import React, { useState, useMemo, useRef, useEffect } from 'react';
import S from 'StyledRegisterWizardForm.js';
import Z from 'StyledRegisterFormByMyId.js';
import { translation, redirect, pushCustomEventToGoogleAnalytics } from 'helpers/utilsHelper.js';
import usePrevious from 'UsePrevious.js';
import { SubmissionError, reset, change } from 'redux-form';
import { connect, useDispatch } from 'react-redux';
import _pick from 'lodash/pick';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _size from 'lodash/size';
import useToggle from 'UseToggle.js';
import ModalOpacity from 'ModalOpacity.js';
import { fetchNewCustomerDataByMyId } from 'customerDataByMyIdActions.js';
import { loadPublicRegConsents } from 'customerActions.js';
import Loader from 'Loader.js';
import { registerUserByMyId } from 'authActions.js';
import RegisterStepLiner from 'RegisterStepLiner.js';
import RegisterFormByMyIdStep1 from 'RegisterFormByMyIdStep1.js';
import RegisterFormByMyIdStep2 from 'RegisterFormByMyIdStep2.js';
import RegisterByMyIdAttributes from 'registerByMyIdAttributes.enum.js';

import VerifiedIcon from 'verified-icon.svg';
import { bindActionCreators } from 'redux';

const RegisterFormByMyId = ({registerByMyIdIdentificator, formData, customerDataByMyId, isPending}) => {

    const [step, setNextStep] = useState(1);
    const prevStep = usePrevious(step);
    const [errorCode, setRegisterErrorCode] = useState(0);
    const [showConfirmationModal, toggleConfirmationModal] = useToggle(false);
    const [showErrorModal, toggleErrorModal] = useToggle(false);
    const [submitInProgress, setSubmitInProgress] = useState(false);
    const customerRegStepTwoTag = 'CUSTOMER_REGISTRATION_STEP_2';
    const [consents, setRegConsents] = useState([]);

    const dispatch = useDispatch();

    const getNewCustomerDataByMyId = async() => {

        return await dispatch(fetchNewCustomerDataByMyId(registerByMyIdIdentificator));

    };

    useEffect(() => {  

        getNewCustomerDataByMyId(registerByMyIdIdentificator).then( response => {
            const {id} = response;
            if (!id){
                app.modal.RegisterByMyId.hide();
                openErrorModal(response);
                redirect('/register');
            }
        });

    }, [])  

    useEffect(() => {  
        getPublicRegConsents()  
            .then(data => setRegConsents(data));  
    }, []) 

    const getPublicRegConsents = async() => {
        try {
            const data = await dispatch(loadPublicRegConsents(customerRegStepTwoTag));
            return data.CUSTOMER_REGISTRATION_STEP_2;
        } catch (error) {
            throw error;
        }
    };

    const goToNextPage = () => {
        setNextStep((prevStep) => prevStep + 1);
    };

    const closeRegister = () => {
        dispatch(reset('registerByMyIdForm'));
        app.modal.RegisterByMyId.hide();
        redirect('/register'); 
    };

    const errorsDataLayerLettersMap = useMemo(() => {
        return {
            'email': 'e',
            'password': 'h',
            'mobile': 't',
            'rodoClause': 'z',
            'firstName': 'i',
            'lastName': 'n',
            'personalIdentificationNumber': 'p',
            'dateOfBirth': 'u'
        }
    }, []);
    
    const checkErrorsForDataLayer = (synchronousErrors, asynchronousErrors, formValues) => {
        let stepErrors = {...synchronousErrors, ...asynchronousErrors};

        if(Object.keys(stepErrors).length) {
            const errorsLettersArray = [];
            for(let error in stepErrors) {
                if(errorsDataLayerLettersMap[error]) {
                    errorsLettersArray.push(errorsDataLayerLettersMap[error])
                }
            }

            if(errorsLettersArray.length){
                pushCustomEventToGoogleAnalytics({ 'event': `register-myid-${step}step-failed`, 'errors': errorsLettersArray})
            }    
        }
    };

    const registerFirstStep = () => {
        goToNextPage();
    };

    const registerSecondStep = async () => {

        let values = formData.registerByMyIdForm.values;
        try {
            let pickedValues = [
                'firstName',
                'lastName',
                'street',
                'country',
                'number',
                'city',
                'bankAccountNumber',
                'countryOfBirth',
                'dateOfBirth',
                'documentExpirationDate',
                'documentNumber',
                'documentType',
                'email',
                'mobile',
                'nationality',
                'password',
                'personalIdentificationNumber',
                'postalCode',
                'identifier'
            ];

            const promoCode = _get(values, ['promoCode']);
            if (promoCode) {
                pickedValues = [...pickedValues, 'promoCode'];
            }

            let registerData = _pick(values, pickedValues);

            if ('dateOfBirth' in registerData) {
                registerData['dateOfBirth'] = registerData['dateOfBirth'].replace(/[\/]/g, '-');
            }

            // // temporary disable KASOMANIA
            registerData['accountConsents'] = [
                // { "name": "LOTTERY_PARTICIPATION", "consent": values['kasomania'] },
                { "container": "LOTTERY_PARTICIPATION", "accepted": false},
                { "container": "REGULATIONS", "accepted" : true },
                { "container": "EMAIL", "accepted": `${_get(values, ['emailConsent'])}` },
                { "container": "SMS", "accepted": `${_get(values, ['smsConsent'])}` }
            ]


            if (!_.isEmpty(consents)){
                const filteredConsents = consents.filter((consent) => consent.visibleForPlayer && consent.container == 'OFFERS' );
                filteredConsents.map((bonus, index) => {
                    if(_get(values, ['isBonusConsent'+index])) {
                        registerData['accountConsents'].push({ "container": "OFFERS", "accepted": `${_get(values, ['isBonusConsent'+index])}`, "definitionId": bonus.definitionId})
                    } else {
                        registerData['accountConsents'].push({ "container": "OFFERS", "accepted": "false", "definitionId": bonus.definitionId});
                    }
                })
            }

            const customerId = await registerOnSubmit(registerData);
            if (app.config.enableAffiliateCampaigns) {
                app.service.Affiliates.notifyCampaigns("registration-end-success", {"{customerId}" : customerId});
            }

            pushCustomEventToGoogleAnalytics({ 'event':'register-myid-success'})

            toggleConfirmationModal();
            

        } catch (error) {
            openErrorModal(error);
            throw new SubmissionError({ _error: error });
        }
    };

    const registerOnSubmit = async (postData) => {
        try {
            setSubmitInProgress(true);
            const newUser = await dispatch(registerUserByMyId(postData));
            setSubmitInProgress(false);
            app.modal.RegisterByMyId.hide();
            redirect('/');
            app.modal.Login.loginAfterRegister(postData);
            return newUser;
        } catch (error) {
            setSubmitInProgress(false);
            throw error;
        }
    };

    const openErrorModal = (error) => {
        let errorCode;
        if (!_.isObject(error)){
            const errorCodeMessage = error.split('_');
            errorCode = errorCodeMessage[errorCodeMessage.length - 1];
        } else {
            errorCode = error.code;
        }
        setRegisterErrorCode(errorCode);
        toggleErrorModal();
    };

    const prepareInitialValuesStep1 = (customerDataByMyId) => {

        const { id, attributes } =  customerDataByMyId;
    
        let userDataStep1 = {};

        userDataStep1['identifier'] = id;
        
        const firstNameObject = _.findWhere(attributes, {attribute: RegisterByMyIdAttributes.FIRST_NAME});
        userDataStep1['firstName'] = firstNameObject?.value ? firstNameObject.value : '';
    
        const lastNameObject = _.findWhere(attributes, {attribute: RegisterByMyIdAttributes.LAST_NAME});
        userDataStep1['lastName'] = lastNameObject?.value ? lastNameObject.value : '';
    
        const addressStreetObject = _.findWhere(attributes, {attribute: RegisterByMyIdAttributes.ADDRESS_STREET});
        userDataStep1['street'] = addressStreetObject?.value ? addressStreetObject.value : '';

        const addressCityObject = _.findWhere(attributes, {attribute: RegisterByMyIdAttributes.ADDRESS_CITY});
        userDataStep1['city'] = addressCityObject?.value ? addressCityObject.value : '';

        const documentExpirationDateObject = _.findWhere(attributes, {attribute: RegisterByMyIdAttributes.DOCUMENT_EXPIRY_DATE});
        userDataStep1['documentExpirationDate'] = documentExpirationDateObject?.value ? documentExpirationDateObject.value : '';

        const addressNumberObject = _.findWhere(attributes, {attribute: RegisterByMyIdAttributes.ADDRESS_NUMBER});
        userDataStep1['number'] = addressNumberObject?.value ? addressNumberObject.value : '';

        const documentTypeObject = _.findWhere(attributes, {attribute: RegisterByMyIdAttributes.DOCUMENT_TYPE});
        userDataStep1['documentType'] = documentTypeObject?.value ? documentTypeObject.value : '';

        const dateOfBirthObject = _.findWhere(attributes, {attribute: RegisterByMyIdAttributes.DATE_OF_BIRTH});
        userDataStep1['dateOfBirth'] = dateOfBirthObject?.value ? dateOfBirthObject.value : '';

        const personalIdentifierObject = _.findWhere(attributes, {attribute: RegisterByMyIdAttributes.PERSONAL_IDENTIFIER});
        userDataStep1['personalIdentificationNumber'] = personalIdentifierObject?.value ? personalIdentifierObject.value : '';

        const bankAccountNumberObject = _.findWhere(attributes, {attribute: RegisterByMyIdAttributes.BANK_ACCOUNT_NUMBER});
        userDataStep1['bankAccountNumber'] = bankAccountNumberObject?.value ? bankAccountNumberObject.value : '';

        const addressCountryNameObject = _.findWhere(attributes, {attribute: RegisterByMyIdAttributes.ADDRESS_COUNTRY_NAME});
        userDataStep1['country'] = addressCountryNameObject?.value ? addressCountryNameObject.value : '';

        const nationalityObject = _.findWhere(attributes, {attribute: RegisterByMyIdAttributes.NATIONALITY});
        userDataStep1['nationality'] = nationalityObject?.value ? nationalityObject.value : '';

        const documentNumberObject = _.findWhere(attributes, {attribute: RegisterByMyIdAttributes.DOCUMENT_NUMBER});
        userDataStep1['documentNumber'] = documentNumberObject?.value ? documentNumberObject.value : '';

        const postCodeObject = _.findWhere(attributes, {attribute: RegisterByMyIdAttributes.POSTAL_CODE});
        userDataStep1['postalCode'] = postCodeObject?.value ? postCodeObject.value : '';

        const countryOfBirthObject = _.findWhere(attributes, {attribute: RegisterByMyIdAttributes.COUNTRY_OF_BIRTH});
        userDataStep1['countryOfBirth'] = countryOfBirthObject?.value ? countryOfBirthObject.value : '';

        const promoCodeObject = _.findWhere(attributes, {attribute: RegisterByMyIdAttributes.PROMO_CODE});
        userDataStep1['promoCode'] = promoCodeObject?.value ? promoCodeObject.value : '';

        const emailObject = _.findWhere(attributes, {attribute: RegisterByMyIdAttributes.EMAIL});
        userDataStep1['email'] = emailObject?.value ? emailObject.value : '';

        const mobileObject = _.findWhere(attributes, {attribute: RegisterByMyIdAttributes.MOBILE});
        userDataStep1['mobile'] = mobileObject?.value ? mobileObject.value.substring(3, mobileObject.value.length) : '';
    
        return userDataStep1;
    };

    const prepareInputsReadOnlyStep1 = (customerDataByMyId) => {

        const { attributes } =  customerDataByMyId;

        let inputs = [];

        if (!attributes) {
            return inputs;
        }
        
        for (let index=0; index<attributes.length; index++){
            const {attribute, readOnly} = attributes[index];
            if (readOnly) {
                inputs.push(attribute);
            }
        }

        return inputs;
    };

   const initialValuesStep1 = prepareInitialValuesStep1(customerDataByMyId);
   const inputsReadOnlyStep1 = prepareInputsReadOnlyStep1(customerDataByMyId);

    return (
        <S.RegisterWizardForm>

            <ModalOpacity isOpen={showConfirmationModal}
                toggleOpen={toggleConfirmationModal}
                title="Confirmation"
                showHeader={false}
                padding={0}>
                <S.ConfirmationModal>
                    <S.ConfirmationTitle dangerouslySetInnerHTML={{ __html: translation('account_registerForm_confirmationTitle') }}></S.ConfirmationTitle>
                    <S.ConfirmationIcon dangerouslySetInnerHTML={{ __html: VerifiedIcon }}></S.ConfirmationIcon>
                    <S.ConfirmationMsg dangerouslySetInnerHTML={{ __html: translation('account_registerForm_confirmationMessage') }}></S.ConfirmationMsg>
                    <S.ConfirmationCloseBtn onClick={() => {
                        toggleConfirmationModal();
                        dispatch(reset('registerWizardForm'));
                    }}>{translation('common_close')}</S.ConfirmationCloseBtn>
                </S.ConfirmationModal>
            </ModalOpacity>

            <ModalOpacity isOpen={showErrorModal}
                toggleOpen={toggleErrorModal}
                title="Error"
                showHeader={false}
                padding={0}>
                <S.ErrorModal>
                    <S.CloseIcon onClick={toggleErrorModal} />
                    <S.ErrorTitle dangerouslySetInnerHTML={{ __html: translation('account_registerForm_errorTitle')}}></S.ErrorTitle>
                    <S.ErrorMessage dangerouslySetInnerHTML={{ __html: translation('account_registerForm_errorMessage') }}></S.ErrorMessage>
                    <S.ErrorCode >{translation('account_registerForm_errorCode')} {`${errorCode}`}</S.ErrorCode>
                    <S.ErrorIcon>
                        <div className="errorGuy"></div>
                    </S.ErrorIcon>
                    <S.ErrorConfirmBtn onClick={() => {
                        redirect('/');
                        toggleErrorModal();
                    }}>{translation('account_registerForm_errorMainPage')}</S.ErrorConfirmBtn>
                </S.ErrorModal>
            </ModalOpacity>

            <S.Header>
              <S.Title>
                {step == 1 ? translation('account_registerForm_checkData') : translation('account_registerForm_title')}
              </S.Title>
              <S.CloseIcon className="closeButton" onClick={closeRegister} />
            </S.Header>

            <Z.Body>

                <RegisterStepLiner step={step}></RegisterStepLiner>

                {(isPending) ?
                   <Loader/>
                    :
                    (step == 1 &&
                        <RegisterFormByMyIdStep1
                            onSubmit={registerFirstStep}
                            formData = {formData}
                            initialValues = {initialValuesStep1}
                            inputsReadOnly = {inputsReadOnlyStep1}
                            checkErrorsForDataLayer={checkErrorsForDataLayer}> 
                        </RegisterFormByMyIdStep1>   
                    )
                }

                {(step == 2 &&
                    <RegisterFormByMyIdStep2 
                        onSubmit={registerSecondStep}
                        formData = {formData}
                        containers={consents}
                        checkErrorsForDataLayer={checkErrorsForDataLayer}
                        inputsReadOnly={inputsReadOnlyStep1}
                        submitInProgress={submitInProgress}>
                    </RegisterFormByMyIdStep2>
                )}

            </Z.Body>

        </S.RegisterWizardForm>
    );

}

const mapStateToProps = (state, props) => {

    const {CustomerDataByMyId: {customerDataByMyId, isPending}} = state;
    const { form: registerByMyIdForm } = state;

    return {
        isPending,
        customerDataByMyId,
        formData: registerByMyIdForm,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchNewCustomerDataByMyId: bindActionCreators(fetchNewCustomerDataByMyId, dispatch),
        dispatch
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(RegisterFormByMyId);