const theme = {
    // Smartphones Mobiles (Portrait)
    mqMobile: `@media (min-width: 320px) and (max-width: 480px)`,
    // Smartphones Mobiles (Landscape)
    mqMobileHorz: `@media (min-width: 481px) and (max-width: 767px)`,
    // Tablets, Ipads (Portrait)
    mqTablets: `@media all (min-width: 768px) and (max-width: 1024px)`,
    // Tablets, Ipads (Landscape)
    mqTabletsHorz: `@media all (min-width: 768px) and (max-width: 1024px) and (orientation: landscape)`,
    // Desktops
    mqSmallDesktops: `@media (min-width: 1025px)`,
    mqMediumDesktops: `@media (min-width: 1281px)`,
    mqLargeDesktops: `@media (min-width: 1600px)`,
    mqXLargeDesktops: `@media (min-width: 1920px)`,

    $fontFamily: `'Barlow', sans-serif`,
    $fwRegular: 400,
    $fwMedium: 500,
    $fwSemibold: 600,
    $fwBold: 700,

    // Main colors
    $color_1: `#FFF`,
    $color_2: `#E1E5E6`,
    $color_3: `#0000003D`,
    $color_4: '#D32F2F',
    $color_5: '#F2F2F2',
    $color_6: '#D9DCE1',
    $color_7: '#9A9B9B',
    $color_8: '#54C341',
    $color_9: '#777D85',
    $color_10: '#C62828',
    $color_11: '#000',
    $color_12: '#EFF2F3',
    $color_13: '#2A9B1A',
    $color_14: '#c5b8b9',
    $color_15: '#C2C2C2',

    $color_16: '#1A6734',
    $color_17: '#9D9D9D',
    $color_18: '#FF3333',
    $color_19: '#6C7173',
    $color_20: '#E7EFEA',
    $color_21: '#FFE2E0',
    $color_22: '#808080',
};


//register form
theme['$registerFormStepColor'] = theme['$color_17'];
theme['$registerTooltipButtonColor'] = theme['$color_16'];
theme['$registerHeaderTextColor'] = theme['$color_16'];
theme['$registerTooltiopByMyIdBorderColor'] = theme['$color_16'];

theme['$registerFormTextColor'] = theme['$color_19'];
theme['$registerFormInputBorderColor'] = theme['$color_16'];
theme['$registerFormInputBackgroundColor'] = theme['$color_1'];
theme['$registerFormInputColor'] = theme['$color_11'];
theme['$registerFormInputLabelColor'] = theme['$color_16'];
theme['$registerFormErrorColor'] = theme['$color_18'];
theme['$registerFormCheckboxTextColor'] = theme['$color_17'];
theme['$registerInputDisabledBorderColor'] = theme['$color_22'];

theme['$registerFormBackgroundColor'] = theme['$color_1'];
theme['$registerFormLabelColor'] = theme['$color_11'];
theme['$registerFormLabelBgColor'] = theme['$color_1'];
theme['$registerFormLinkColor'] = theme['$color_4'];
theme['$registerFormSubmitButtonBgColor'] = theme['$color_16'];
theme['$registerFormSubmitButtonColor'] = theme['$color_1'];
theme['$registerFormPreviousButtonBgColor'] = theme['$color_1'];
theme['$registerFormPreviousButtonColor'] = theme['$color_7'];


theme['$registerFormConfirmationCloseBtnBgColor'] = theme['$color_10'];


//account limits modal
theme['$accountLimitsHeaderTextColor'] = theme['$color_16'];
theme['$accountLimitsOptionBackgroundColor'] = theme['$color_17'];
theme['$accountLimitsOptionBackgroundActiveColor'] = theme['$color_16'];
theme['$accountLimitsInfoBackgroundColor'] = theme['$color_20'];
theme['$accountLimitsTextColor'] = theme['$color_19'];
theme['$accountLimitsInputBorderColor'] = theme['$color_16'];
theme['$accountLimitsInputBackgroundColor'] = theme['$color_1'];
theme['$accountLimitsInputColor'] = theme['$color_11'];
theme['$accountLimitsInputLabelColor'] = theme['$color_16'];
theme['$accountLimitsErrorColor'] = theme['$color_18'];
theme['$accountLimitsErrorBackgroundColor'] = theme['$color_21'];
theme['$accountLimitsBackgroundColor'] = theme['$color_1'];

export default theme;