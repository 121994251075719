import styled, {css} from 'styled-components';
import {rem} from 'polished';
import StyledLoader from 'StyledLoader.js';
import {Form, Input, Button} from 'global/styles/styles.js';

const FontFamily = ({theme: {$fontFamily}}) => `${$fontFamily}`;
const FwRegular = ({theme: {$fwRegular}}) => `${$fwRegular}`;

const baseFont = `12px`;
const S = {};

const RegisterStepColor = ({ theme: { $registerFormStepColor } }) => `${$registerFormStepColor}`;
const RegisterHeaderTextColor = ({ theme: { $registerHeaderTextColor } }) => `${$registerHeaderTextColor}`;

const FormBackground = ({ theme: { $registerFormBackgroundColor } }) => $registerFormBackgroundColor;
const InputBorderColor = ({ theme: { $registerFormInputBorderColor } }) => `${$registerFormInputBorderColor}`;
const InputErrorColor = ({ theme: { $registerFormErrorColor } }) => `${$registerFormErrorColor}`;
const InputTextColor = ({ theme: { $registerFormInputColor } }) => `${$registerFormInputColor}`;
const InputBackground = ({ theme: { $registerFormInputBackgroundColor } }) => `${$registerFormInputBackgroundColor}`;
const InputPlaceholderColor = ({ theme: { $registerFormTextColor } }) => `${$registerFormTextColor}`;
const CheckboxTextColor = ({ theme: { $registerFormCheckboxTextColor } }) => `${$registerFormCheckboxTextColor}`;
const InputLabelColor = ({ theme: { $registerFormInputLabelColor } }) => `${$registerFormInputLabelColor}`;
const InputBorderDisabledColor = ({ theme: { $registerInputDisabledBorderColor } }) => `${$registerInputDisabledBorderColor}`;

const LabelMixin = css`
    margin-top: -1.3rem;
    font-size: 0.825rem;
    color: ${InputLabelColor};
    min-width: 0;
    & .bottomLabel {
        display: none;
    }
    & .topLabel {
        display: block;
    }
`;

const ReactComponentTooltip = css`
    .__react_component_tooltip {
        &.type-light {
            &.place-top {
                max-width: 42%;
                &:after {
                    border-left: 8px solid transparent;
                    border-right: 8px solid transparent;
                    bottom: -6px;
                    left: 50%;
                    margin-left: -8px;

                }
                &:before {
                    border-left: 9px solid transparent;
                    border-right: 9px solid transparent;
                    margin-left: -9px;
                    border-top-color: rgba(26, 103, 52, 0.5);
                    border-top-style: solid;
                    border-top-width: 6px;
                }
            }
        }

        &.show {
            opacity: 1;
            box-shadow: 0px 0px 0px 2px rgba(26, 103, 52, 0.5);
        }
    }
`


S.RegisterWizardForm = styled.div`
  font-family: ${FontFamily};
  position: relative;
  background-color: #fff;
  width: 70%;
  margin: 0 auto;
`;

S.RegisterWizardFormStep1 = styled(Form)` 
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    background-color: ${FormBackground};
    padding: 1.1875rem;
    padding-bottom: 1.3rem;
`;

S.RegisterWizardFormStep2 = styled(S.RegisterWizardFormStep1)`
`;

S.SubmitButton = styled(Button)`
    box-sizing: border-box;
    width: 100%;
    border: none;
    border-radius: 1.50rem;
    height: 3rem;
    text-align: center;
    font-size: 1.6rem;
    color: ${FormBackground};
    background-color: ${InputBorderColor};

    &:disabled {
        background: ${InputPlaceholderColor};
        color: ${FormBackground};
    };
    }
`;

S.ResendVerificationCodeButton = styled(S.SubmitButton)`
    margin-bottom: 1.5rem;
    font-size: 1.1rem;
    font-weight: 700;
`;

S.ConfirmationModal = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   flex: 1;
   padding: 3rem;
`;

S.ConfirmationMsg = styled.div`
  width: 100%;
  text-align: center;
  font-size: 1.3rem;
  margin: 3rem 0;
`;

S.ConfirmationCloseBtn = styled(S.SubmitButton)`
    width: 60%;
    text-transform: uppercase;
`;

S.ConfirmationIcon = styled.div`
    width: 65px;
    height: 65px;
`;

S.ConfirmationTitle = styled.div`
    width: 100%;
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 3rem;
    color: ${InputBorderColor};
`;

S.ErrorModal = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    min-width: 0;
    padding: 1.88rem 0.88rem;
    padding-left: 3rem;
    line-height: 1;
    gap: 3rem;
`;

S.ErrorCloseBtn = styled.div`
    position: absolute;
    top: -2.5rem;
    right: 2.5rem;
`;

S.ErrorTitle = styled.div`
    font-size: 3.25rem;
    color: ${InputBorderColor};
    font-weight: 700;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
`;

S.ErrorMessage = styled.div`
    font-size: 1.55rem;
    color: ${InputTextColor};
    font-weight: 500;
    align-self: center;
`;

S.ErrorConfirmBtn = styled.div`
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    width: 17.56rem;
    height: 3.31rem;
    border-radius: 0.5rem;
    font-size: 1.36rem;
    font-weight: 500;
    background-color: ${InputBorderColor};
    color: ${FormBackground};
`;

S.StepsLineWrapper = styled.div`
  margin-top: 2.5rem;

  & .stepsContainer {
    display: flex;
    justify-content: center;
    padding: 1rem 0;
  }

  & .step {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & .stepIcon {
      width: 5rem;
      height: 5rem;
    }
    & .stepTitle {
      margin-top: 0.31rem;
      font-size: 1.06rem;
      color: ${RegisterStepColor};
    }
  }

  & .separator {
    width: 4.38rem;
    position: relative;
    height: 100%;
    align-self: center;
    margin: 0 1.56rem 1rem;
      &:after {
        content: "";
        display: block;
        border-bottom: 0.19rem solid ${RegisterStepColor};
      }
  }
`;


S.Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: ${rem('42px', baseFont)};
  position: relative;
`;

S.Title = styled.h2`
   margin: 0;
   color: ${RegisterHeaderTextColor};
    font-size: ${rem('32px', baseFont)};
    padding: 0;
    font-weight: ${FwRegular};
    text-align: center;
`;

S.CloseIcon = styled.div`
    position: absolute;
    right: -1.5rem;
    top: 2rem;
    padding: 0;
    transform: none;
    line-height: 1;
    cursor: pointer;
    color: #000;
    &:before{
      content: "\\00d7";
      font-size: ${rem('67px', baseFont)};
    }
`;

S.Cover = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: -100px;
    right: -95px;
    background: rgba(0,0,0,0.5);
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 5;
    
    & ${StyledLoader.LoaderWrapper}{
    }
`;

S.Row = styled.div`
    width: 100%;
    &.left {
        width: 45%;
        margin-right: 5%;
    }
    &.right {
        width: 45%;
        margin-left: 5%;
    }
`;

S.PasswordToggler = styled.div`
    position: absolute;
    right: 5%;
    top: 0.85rem;
    height: 1.56rem;
    width: 1.56rem;
    font-size: 1.25rem;

    &.checkbox-toggler{
      top: 0.25rem;
    }
`;

S.PasswordTogglerIcon = styled.div``;

S.TooltipToggler = styled(S.PasswordToggler)``;

S.TooltipTogglerIcon = styled(S.PasswordTogglerIcon)``;


S.Label = styled.label`
    pointer-events: none;
    color: ${InputPlaceholderColor};
    text-align: left;
    position: absolute;
    left: 0.81rem;
    top: 0.7rem;
    width: auto;
    font-size: 1.36rem;
    font-weight: 400;
    padding: 0 0.5rem;
    transition: .2s;
    background-color: ${InputBackground};
`;

S.PasswordStrengthChecker = styled.div`
    display: flex;
    align-items: center;
    justify-items: flex-start;
    flex-wrap: wrap;
    padding: 0 1.31rem;
    margin-top: 0.19rem;
    line-height: 1rem;
`;

S.PasswordField = styled.span`
    color: ${InputBorderColor};
    font-size: 0.89rem;
    margin: 0 0.25rem 0 0;
    &.no-touched {
        color: #787d83;
    }
    &.has-error {
        color: ${InputErrorColor};
        font-weight: bold;
    }
    &:after {
        content: '\\2022';
        font-size: 1rem;
        margin-left: 0.25rem;
        color: ${InputTextColor};
    }
    &:last-child {
        &:after {
            display: none;
        }
    }
`;

S.FakeChbox = styled.div`
    position: relative;
    z-index: 3;
    flex-shrink: 0;
    width: 15px;
    height: 15px;
    border: 0.13rem solid ${InputPlaceholderColor};
    margin-right: 1.23rem;
    background-color: ${FormBackground};
    pointer-events: none;
    cursor: pointer;
    &.has-error {
        border: 0.13rem solid ${InputErrorColor};
    }
    & ~ div {
        padding: 0 2.45rem;
    }
`;

S.CheckMark = styled.span`
    &:after {
        position: absolute;
        top: -0.1rem;
        left: 0.1rem;
        display:block;
        content: '\f00c';
        font-family: FontAwesome;
        color: ${InputBorderColor};
        font-size: 0.9rem;
    }
`;

S.Input = styled(Input)`
    border: 2px solid ${InputBorderColor};
    border-radius: 0.74rem;
    background-color: ${InputBackground} !important;
    height: 3.53rem;
    color: ${InputTextColor};
    font-size: 1.25rem;
    box-sizing: border-box;
    width: 100%;
    padding: 0px 1.33rem;
    &[name="mobile"] {
        letter-spacing: 0.075rem; // to separate
    }

    &.has-prefix-mobile {
        padding-left: 3.3rem;
    }

    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px ${InputBackground} inset !important;
        transition: background-color 5000s ease-in-out 0s;
        background-color: ${InputBackground} !important;
        &:hover,
        &:focus,
        &:active {
            -webkit-box-shadow: 0 0 0px 1000px ${InputBackground} inset !important;
            transition: background-color 5000s ease-in-out 0s;
            background-color: ${InputBackground} !important;
        }
    }

    & + label {
        background-color: ${InputBackground};
        & .topLabel {
            display: none;
        }
        & .bottomLabel {
            display: block;
        }
    }

    &:valid,
    &.is-valid {
        &[value]:not([value=""]) {
            & + label {
                ${LabelMixin};
            }
        }
    }
    &:focus {
        outline: 0;
        & + label {
            ${LabelMixin};
        }
    }

    &.disabled-input {
      &[value]:not([value=""]) {
        & + label {
          ${LabelMixin};
        }
      }
      border: 0.13rem solid ${InputBorderDisabledColor};
    }

    &.has-error {
        border: 2px solid ${InputErrorColor};
        &[value]:not([value=""]) {
            & + label {
                ${LabelMixin};
            }
        }
    }
`;

S.Info = styled.div`
    flex: 1 100%;
    padding: 0 1.31rem;
    margin-top: 0.19rem;
    font-size: 1rem;
    text-align: center;
    color: ${InputLabelColor};
`;

S.Error = styled(S.Info)`
    text-align: left;
    font-size: 0.89rem;
    font-weight: 700;
    color: ${InputErrorColor};
`;

S.ValidInfo = styled(S.Info)`
    text-align: left;
    color: ${InputLabelColor};
`;

S.SubmissionError = styled.div`
    margin: 0;
    border-radius: .25rem;
    width: 100%;
    color: #CE0006;
    font-size: 1rem;
    padding: .83rem;
    background-color: #fba59f;
    margin-bottom: .5rem;
    text-align: center;
`;

S.InputWrapper = styled.div`
    position: relative;
    padding: 0 0 1.75rem;
    width: 100%;
    margin: 0 auto;

    .prefix-mobile {
        position: absolute;
        font-size: 1.25rem;
        top: 0.91666rem;
        left: 1rem;
    }
        
    &.with-select{
 
        ${S.Label}{
            ${LabelMixin}
        }
     
         &.no-margin{
          margin-bottom: 0;
         }
    }
    &.with-checkbox{
       display: flex;
        flex-wrap: wrap;
        margin-bottom: ${rem('10px', baseFont)};
        
        ${S.Error}{
           width: 100%;
        }  
        ${S.Label}{
            flex: 1;
            font-size: ${rem('10px', baseFont)};
            font-weight: 400;
            padding-top: 0;
            color: rgba(0, 0, 0, 0.87);
            display: block;
            position: relative;
            left: 0;
            cursor: pointer;
            top: 0;
            align-items: flex-end;
            pointer-events: auto;
           
        }
         ${S.Input}{
            position: absolute!important;
            padding: 0!important;
            border: 0!important;
            overflow: hidden;
            z-index: 0;
            left: 3px;
            top: 0;
            height: 12px;
            width: auto;
            cursor:pointer;
            &:checked + ${S.FakeChbox}{
              background-color: #D32F2F;
              border-color: #D32F2F;
            }
        }
    }

    ${ReactComponentTooltip}
`;

S.CheckboxWrapper = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: 0 0 1.5rem 0 ;

    &[name^="isBonus"] {
        label {
            font-size: 1.5rem;
            max-width: 80%;
        }

        input {
            height: 21px;
            width: 21px;
        }

        ${S.FakeChbox} {
            height: 22px;
            width: 22px;

            ${S.CheckMark} {
                &:after {
                    font-size: 1.3rem;
                }
            }
        }
    }

    &.indentation {
        margin-left: 2rem;
    }

    input {
        position: absolute !important;
        padding: 0 !important;
        margin: 0 !important;
        border: 0 !important;
        overflow: hidden;
        z-index: 0;
        left: 0;
        top: 0;
        height: 14px;
        width: 14px;
        cursor: pointer;

        &:checked {
            & + div {
                border-color: ${InputBorderColor};
            }
        }
    }
    &[name="selectAllFields_step1"], &[name="selectAllFields_step2"] {
        border-bottom: 2px solid ${InputPlaceholderColor};
        margin-bottom: 1.5rem;
    }

    label {
        color: ${CheckboxTextColor};
        font-size: 0.95rem;
        font-weight: 500;
        display: inline;
        text-align: left;
        position: relative;
        left: 0px;
        top: 0px;
        flex: 1 1 0%;
        align-self: center;

        a {
            color: ${InputBorderColor};
            text-decoration: none;
        }

        span {
            :nth-of-type(2) {
                color: ${InputBorderColor};
                margin-left: 0.5rem;
                cursor: pointer;
            }
        }
    }

    ${S.Error} {
        margin-bottom: -17px;
    }

    ${ReactComponentTooltip}
`;

S.AdditionalInfo = styled.div`
    text-align: left;
    font-size: 0.95rem;
    color: #000;
    line-height: 1.7;
    margin: 2rem 0 0;
    font-weight: 500;
    cursor: pointer;
`;

S.AdditionalInfoGrey = styled(S.AdditionalInfo)`
    color: ${CheckboxTextColor};
    font-size: 0.95rem;
    margin: 0.5rem 0 1.5rem 2.3rem;
    text-align: justify;
    span {
        :nth-of-type(2) {
            color: ${InputBorderColor};
            margin-left: 0.5rem;
            cursor: pointer;
        }
    }
    cursor: initial;
`;

S.Link = styled.span`
    color: ${InputBorderColor};
    font-size: 0.95rem;
    margin-left: 0.5rem;
    font-weight: 700;
    cursor: pointer;
`;

S.Select = styled.div`
    cursor: pointer;
    align-items: stretch;
    display: block;

    & .react-select__menu{
        margin-top: 0;
        z-index: 5;
        
        & .react-select__menu-list{
             padding: 0;
        
            & > div{
                cursor: pointer;
            }
            
              .react-select__option {
               color: rgba(0, 0, 0, 0.7);
               font-size: 1.16rem;
              }
              
            &--is-multi {
                .react-select__option {
                    color: #777D85;
                    &--is-focused {
                       background-color: #E8E8E8;
                    }
                    &--is-selected {
                        background-color: inherit;
                        font-weight: 600;
                        color: #262626;
                        
                        &.react-select__option {
                            &--is-focused {
                                background-color: #E8E8E8;
                            }
                        }
                    }
                }                
            }
        }
    }
    
    & .react-select__control{
        box-shadow: none;
        min-height: ${rem('42px', baseFont)};
        border: 2px solid ${InputBorderColor};
        border-radius: 0.74rem;
        align-items: flex-start;
        background-color: #fff;
        & .react-select__dropdown-indicator{
            padding: 3px;
            padding-right: 1.5rem;
        }
        
        & .react-select__clear-indicator{
            display: none;
        }
        
        & .react-select__indicator-separator{
            display: none;
        }
        
        & .react-select__value-container{
             margin-top: ${rem('10px', baseFont)};
             margin-left: ${rem('5px', baseFont)};
             margin-right: ${rem('15px', baseFont)};
            & .react-select__single-value{
                color: rgba(0, 0, 0, 1);
                font-size: 1.25rem;
            }
            &--is-multi {
                flex-wrap: nowrap;
                text-overflow: ellipsis;
                max-width: 90%;
                white-space: nowrap;
                overflow: hidden;
                display: initial;
            }
        }

        &:hover {
            border: 2px solid ${InputBorderColor};
        }
    
    }

`;


S.Body = styled.div`
 padding:  ${rem('30px', baseFont)};
`;


S.PrevStepBtn = styled(Button)`
    display:flex;
    background-color: transparent;
    align-items: center;
    position: absolute;
    padding: 0;
    top: 4.5rem;
    left: -2rem;
`;

S.PrevIcon = styled.div`
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 2.33rem;
    height: ${rem('14px', baseFont)};
    display: flex;
    line-height: 1;
     & svg{
     width: 100%;
        & path{
            fill: #000 !important;
        }
    }
`;

S.RegisterOptionsContainer = styled.div`
    color: ${({theme: {$registerFormStepChooseMethodColor}}) => $registerFormStepChooseMethodColor};
    padding: 1rem;
`;

S.OptionContainer = styled.div`
    border: 1px solid #E9E9E9;
    margin: 2rem 0;
    -webkit-box-shadow: 0px 9px 100px 14px rgba(0, 0, 0, 0.17);
    -moz-box-shadow: 0px 9px 100px 14px rgba(0, 0, 0, 0.17);
    box-shadow: 0px 9px 100px 14px rgba(0, 0, 0, 0.17);
    background: white;
    padding: 3rem;
`;

S.OptionLabelRow = styled.div`
    width: 100%;
    display: flex;
    font-size: 19px;
    line-height: 3rem; 
    font-weight: 500;
    margin-bottom: 1rem;
`;

S.FormOptionIcon = styled.div`
    height: 3rem;
    width: 3rem;
    margin-right: 1rem;
`;

S.OptionDescription = styled.div`
    margin-bottom: 1rem;
`;

S.OptionChooseBtn = styled(Button)`
    background-color: ${({theme: {$registerFormSubmitButtonBgColor}}) => $registerFormSubmitButtonBgColor};
    color: ${({theme: {$registerFormSubmitButtonColor}}) => $registerFormSubmitButtonColor};
    width: 75%;
    text-align: center;
    box-sizing: border-box;
    border-radius: 16px;
    border: none;
    display: block;
    height: 3.2rem;
    line-height: 3.2rem;
    margin: auto;
    font-size: 1.2rem;
    font-weight: 500;
    &:disabled {
        opacity: .3;
    }
`;

S.AdditionalInfoRegByMyId = styled.div`
    text-align: center;
    font-size: 0.81rem;
    color: ${InputTextColor};
    line-height: 1.7;
    margin-top: 1rem;
    width: 100%;
`;

S.LinkHelp = styled.span`
    color: ${InputBorderColor};
    font-size: 0.81rem;
    margin: auto;
    font-weight: 700;
    cursor: pointer;
`;

export default S;
