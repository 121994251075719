import styled from 'styled-components';
import { Button, Form } from 'global/styles/styles.js';

const InputBorderColor = ({ theme: { $registerFormInputBorderColor } }) => `${$registerFormInputBorderColor}`;
const InputTextColor = ({ theme: { $registerFormInputColor } }) => `${$registerFormInputColor}`;
const TooltipButtonColor = ({ theme: { $registerTooltipButtonColor } }) => `${$registerTooltipButtonColor}`;
const TooltipRegisterByMyIdBorderColor = ({ theme: { $registerTooltiopByMyIdBorderColor } }) => `${$registerTooltiopByMyIdBorderColor}`;

const Z = {};

Z.RegisterFormByMyIdContainer = styled.div`
    color: ${({theme: {$registerFormStepChooseMethodColor}}) => $registerFormStepChooseMethodColor};
`;

Z.Header = styled.div`
    margin-top: 1rem;
    position: relative;
    padding: 0 1.06rem;
`;

Z.RegisterWizardFormTitle = styled.h2`
    text-align: center;
    font-size: 1.63rem;
    margin: 0 auto;
    color: ${InputBorderColor};
    font-weight: 400;
`;

Z.HeaderButton = styled(Button)`
    position: absolute;
    top: 0;
    display:flex;
    background-color: transparent;
    align-items: center;
    padding: 0;
    &.closeButton {
        right: 1.06rem;
    }
    &.backButton {
        left: 1.06rem;
    }
`;

Z.HeaderButtonIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.56rem;
    height: 1.56rem;
    cursor: pointer;
    & svg{
        width: 100%;
        & path{
            fill: ${InputTextColor} !important;
        }
    }
`;

Z.Body = styled.div`
    
`;

Z.RegisterFormByMyIdStep1 = styled(Form)` 
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    background-color: ${({theme: {$registerFormBackgroundColor}}) => $registerFormBackgroundColor};
    padding: 1.19rem 1.19rem 1.3rem;
`;

Z.InputIconCheckedContainer = styled.div`
    position: absolute;
    right: 1rem;
    top: 1rem;
`;

Z.InputIconChecked = styled.div`
    width: 1rem;
    height: 1rem;
`;

Z.TooltipModal = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    padding: 3rem;
    padding: 0;
    &.registerByMyId {
        border: 0.1rem solid ${TooltipRegisterByMyIdBorderColor}
    }
`;

Z.TooltipModalMsg = styled.div`
    padding: 1.4rem 1rem 0;
    font-weight: normal;
    &.registerByMyId {
        padding: 0 1rem 1.4rem;
    }
`;

Z.TooltipModalCloseBtn = styled(Button)`
    display: block;
    width: 30%;
    height: 2.625rem;
    padding: 0;
    margin: 0 0.5rem 0 auto;
    background-color: transparent;
    color: ${TooltipButtonColor};
    font-weight: 700;
    font-size: 1.06rem;
    text-transform: uppercase;
`;

export default Z;