/* global app, grDesktopLoader */
import React, { useCallback, useEffect, useState, createRef } from 'react'
import { connect, useDispatch } from 'react-redux'
import { getGoldenRaceGame } from 'integrationActions.js'

const CONTAINER = 'golden-race-desktop-app'
const SCRIPT = 'golden-race-desktop-loader'

const languagesMap = {
  en: 'en-GB',
  es: 'es-ES',
  it: 'it-IT',
  fr: 'fr-BE',
  ja: 'ja-JP',
  ka: 'ka-GE',
  ko: 'ko-KR',
  ms: 'ms-MY',
  nl: 'nl-NL',
  pl: 'pl-PL',
  pt: 'pt-PT',
  ro: 'ro-RO',
  ru: 'ru-RU',
  th: 'th-TH',
  uk: 'uk-UA',
  vi: 'vi-VN',
  zh: 'zh-CN'
}

const GoldenRace = ({ isLogged }) => {
  const { lang, mainCountryCode: countryCode, goldenRace2Profile: profile, goldenRace2UISettings: settingsUI } = app.config

  const dispatch = useDispatch()

  const ref = createRef(null)

  const [url, setUrl] = useState(undefined)
  const [hash, setHash] = useState(undefined)
  const [hardwareId, setHardwareId] = useState(undefined)

  const [scriptTag] = useState(document.createElement('script'))
  const [containerTag] = useState(document.createElement('div'))

  const setGoldenRaceIframe = () => {
    window.grLoader = grDesktopLoader({
      language: languagesMap[lang],
      onAppReady:  function (type, content) {
        openGoldenRaceBetHistory();
      },
      ...settingsUI,
      ...(!!hardwareId && { hwId: hardwareId }),
      ...(!!hash && { onlineHash: hash }),
      ...(!!profile && { profile })
    })
  }

  const placeScript = useCallback(() => {
    !!ref.current && ref.current.appendChild(scriptTag)

    scriptTag.onload = setGoldenRaceIframe
    scriptTag.type = 'text/javascript'
    scriptTag.id = SCRIPT
    scriptTag.src = url
    scriptTag.async = true
  }, [url])

  const openGoldenRaceBetHistory = () => {
    var coupons = app.router.getURLParams();
    if (coupons.coupons != undefined && isLogged) {
        window.grLoader.openBetHistory();
    }
  }

  // Place CONTAINER first thing when component mounts
  useEffect(() => {
    containerTag.id = CONTAINER
    !!ref.current && ref.current.appendChild(containerTag)
  }, [])

  useEffect(() => {
    const getIframe = async () => {
      const { url_script: url, online_hash: hash, hardware_id: hardwareId } = await dispatch(getGoldenRaceGame({
        device: 'WEB',
        demo: !isLogged,
        countryCode
      }))

      setHash(hash)
      setHardwareId(hardwareId)
      setUrl(url)
    }

    getIframe()
  }, [dispatch, isLogged])

  useEffect(() => {
    !!url && placeScript()
  }, [url])

  // Handle user logging in and out
  useEffect(() => {
    if (typeof grDesktopLoader === 'undefined') return
    if (!ref.current.querySelector(`#${CONTAINER}`)) return
    if (!ref.current.querySelector(`#${SCRIPT}`)) return
    // Prevent setup when:
    // both `hash` and `hardwareId` are either available or unavailable at the same time
    if (!hash && !hardwareId) return
    if (!!hash && !!hardwareId) return

    ref.current.querySelector(`#${CONTAINER}`).firstChild &&
    ref.current.querySelector(`#${CONTAINER}`).firstChild.remove()

    setGoldenRaceIframe()
  }, [hardwareId, hash, ref.current])

  return (
    <>
      <div ref={ref} />
    </>
  )
}

const mapStateToProps = ({ Auth: { isLogged } }) => {
  return {
    isLogged
  }
}

export default connect(mapStateToProps)(GoldenRace)