import React from 'react';
import S from 'StyledRegisterStepLiner.js';
import { translation } from 'helpers/utilsHelper.js';

import RegisterStep1 from 'registerStep1.svg';
import RegisterStep2 from 'registerStep2.svg';
import RegisterStep1Active from 'registerStep1Active.svg';
import RegisterStep2Active from 'registerStep2Active.svg';


const RegisterStepLiner = ({ step }) => {
    return (
      <S.StepsLineWrapper>
          <div className={step === 1 ? 'stepsContainer stepOneActive' : 'stepsContainer stepTwoActive'}>
              <div className="step">
                  {step === 1 ? <div className="stepIcon" dangerouslySetInnerHTML={{ __html: RegisterStep1Active }}></div> : <div className="stepIcon" dangerouslySetInnerHTML={{ __html: RegisterStep1 }}></div>}
                  <div className="stepTitle">{translation('account_registerForm_step1')}</div>
              </div>
              <div className="separator"></div>
              <div className="step">
                  {step === 2 ? <div className="stepIcon" dangerouslySetInnerHTML={{ __html: RegisterStep2Active }}></div> : <div className="stepIcon" dangerouslySetInnerHTML={{ __html: RegisterStep2 }}></div>}
                  <div className="stepTitle">{translation('account_registerForm_step2')}</div>
              </div>
          </div>
      </S.StepsLineWrapper>
    )
};

export default RegisterStepLiner;