import React, {useEffect, useMemo, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {compose, bindActionCreators} from 'redux';
import S from 'StyledResponsibleGamingForm.js';
import {Field, reduxForm, SubmissionError, destroy} from 'redux-form';
import {translation} from 'helpers/utilsHelper.js';
import {
  loadCustomerLimits,
  updateCustomerLimits,
  loadCustomerLimitsOptions,
  acceptRegulations} from 'customerActions.js';
import {getLimitIdByKey} from 'limitTypes.enum.js';
import classNames from 'classnames';
import Loader from 'Loader.js';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import _reduce from 'lodash/reduce';
import _compact from 'lodash/compact';
import _isObject from 'lodash/isObject';
import _isEmpty from 'lodash/isEmpty';
import ReactTooltip from 'react-tooltip';
import TogglerIcon from 'register-tooltip-icon.svg';
import validation from 'sb-validate-js/validate.js';
import validators from 'validators.enum.js';

const validate = values => {
  const errors = validation(values, validators.ACCOUNT_LIMITS);
  return errors;
};

const OptionMarker = ({ limitOptions, selectedOption, selectOption, isCustomLimit }) => {
  let temporaryJsxContainer = [];
  for(let option in limitOptions){
    const optionClass = selectedOption?.optionName == option && !isCustomLimit ? 'active' : "";
    temporaryJsxContainer.push(
      <S.LimitOption 
        className={optionClass} 
        key={`${option}_option`} 
        onClick={() => selectOption(limitOptions[option])}>
        <S.LimitBox className="box"></S.LimitBox>
        <S.LimitName>{translation(`limitOption_${option}`)}</S.LimitName>
      </S.LimitOption>
    );

  }

  return temporaryJsxContainer;
};

const FieldWrapper = ({selectedOption, triggerOption}) => {
  let temporaryJsxContainer = [];
  for(let property in selectedOption){
    if(property != "optionName" && property != "acceptOptionLimits"){
      temporaryJsxContainer.push(
        <Field
            key={`${property}_key`}
            name={property}
            type="number"
            component={renderField}
            label={translation(`accountLimits_${property}`)}
            openTooltip={true}
            preventAutoComplete={true}
            triggerOption={triggerOption}
        />
      );
    }
  }
  return temporaryJsxContainer;
}

const renderField = ({ input, label, type, openTooltip, triggerOption, meta: { touched, error, warning, valid, asyncValidating, submitFailed } }) => {

    const inputClass = classNames({
        'async-validating': asyncValidating,
        'has-error': ((touched || submitFailed) && error),
        'is-valid': ((touched || submitFailed) && valid)
    });

    return (
        <S.Row>
            <S.InputWrapper>
                {openTooltip && <TooltipToggler name={input.name}/>}
                <S.Input {...input}
                    type={type}
                    required="required"
                    className={inputClass}
                    onKeyUp={triggerOption}
                />
                <S.Label>
                    {(label && <span className="bottomLabel" dangerouslySetInnerHTML={{ __html: label }} ></span>)}
                </S.Label>
                
                {(touched || submitFailed) && ((error && <S.Error>{error[0]}</S.Error>) || (warning &&
                <S.Error>{warning}</S.Error>))}
            </S.InputWrapper>
        </S.Row>
    );
};

const TooltipToggler = ({name}) => {
  return (
      <>
          <S.TooltipToggler data-tip data-for={`${name}`}>
              <S.TooltipTogglerIcon dangerouslySetInnerHTML={{ __html: TogglerIcon }}></S.TooltipTogglerIcon>
          </S.TooltipToggler>
          <ReactTooltip id={`${name}`} type="light">
              <span>{translation(`accountLimits_${name}Tooltip`)}</span>
          </ReactTooltip> 
      </>
  );
};

const ResponsibleGamingForm = (props) => {

    const {
        updateCustomerLimits,
        loadCustomerLimits,
        loadCustomerLimitsOptions,
        isPending,
        handleSubmit,
        submitting,
        error,
        customerLimits,
        limitOptions,
        change,
        acceptRegulations
    } = props;

    const [selectedLimitOption, setSelectedOption] = useState(0);
    const [isCustomLimit, setIsCustomLimit] = useState(false);

    useEffect(() => {
        loadCustomerLimits();
        const getLimitOptions = async() => {
          const options = await loadCustomerLimitsOptions();
          setSelectedOption(options[Object.keys(options)[0]]);
        }
        getLimitOptions();
    }, []);

    useEffect(() => {
      setLimitValues();
      setIsCustomLimit(false);
    }, [selectedLimitOption]);

    const proccessFormSubmitResponse = (response) => {
      const errors = [];
      _map(response, ({limitType,  exceptionCode}) => {
        if(exceptionCode >= 500){
          const limitName = translation(`accountLimits_limitType${limitType}`);
          const errorMsg = translation(`accountLimits_error_${exceptionCode}`);
          errors.push({
            code: exceptionCode,
            message: `${limitName} - ${errorMsg}`
          });
        }
      })
      if(!_isEmpty(errors) && !errors.every(({ code }) => code === 500)){
        throw {_error: errors.map(({ message }) => message)};
      } else{
        acceptRegulations();
        app.modal.AccountLimits.hide();
        app.system.render(app.modal.AccountDeposit);
      }
    }

    const onFormSubmit = async(values) => {
        try {
            let limitData = _pick(values, fieldsMap);
            limitData = _map(customerLimits, (limit) => {
                const {limitType} = limit;
                const limitAmount = _get(limitData, [`limitType${limitType}`]);
                if (limitAmount) {
                    if (limit.exceptionCode) delete limit.exceptionCode;
                    return {...limit, limitAmount}
                }
                return null;
            });

            limitData = _compact(limitData);
            const response = await updateCustomerLimits(limitData);
            proccessFormSubmitResponse(response);

        } catch (errors) {
            throw new SubmissionError(errors);
        }
    };

    const limitKeysToIdMap = useMemo(() => {
        const limitKeys = [
            'DAILY_STAKE_LIMIT',
            'MONTHLY_STAKE_LIMIT',
            'DAILY_SESSION_TIME_LIMIT',
            'MONTHLY_SESSION_TIME_LIMIT'
        ];
        return _map(limitKeys, (key) => {
            return getLimitIdByKey(key);
        });
    }, []);

    const fieldsMap = useMemo(() => {
        return _reduce(limitKeysToIdMap, (initialArray, id) => {
            return initialArray.concat(`limitType${id}`);
        }, []);
    }, []);

    const triggerOption = () =>{
      setIsCustomLimit(true);
    }

    const selectOption = (option) => {
      if(isCustomLimit && option.optionName == selectedLimitOption.optionName){
        setLimitValues();
      } else{
        setSelectedOption(option);
      }
      setIsCustomLimit(false);
    }

    const setLimitValues = () =>{
      for(let name in selectedLimitOption){
        change(name, selectedLimitOption[name]);
      }
    }

    if (isPending) {
        return <S.LoaderWrapper as={Loader} color="#F05A22"/>;
    }

    return (

        <S.ResponsibleGamingForm className="responsible-gaming" onSubmit={handleSubmit(onFormSubmit)} autocomplete="off">

          <S.Header>
            <S.Title>
              {translation('accountLimits_title')}
            </S.Title>
          </S.Header>
                  
          <S.OptionMarkerWrapper>
            <OptionMarker 
              limitOptions={limitOptions}
              selectedOption={selectedLimitOption}
              selectOption={selectOption}
              isCustomLimit={isCustomLimit}
            />
          </S.OptionMarkerWrapper>

          <S.LimitsInfo>
            {translation('accountLimits_info')}
          </S.LimitsInfo>

          {submitting && (<S.Cover><Loader color="#F05A22"/></S.Cover>)}

          <S.Body>
            <S.BodyInner>
              <FieldWrapper selectedOption={selectedLimitOption} triggerOption={triggerOption}></FieldWrapper>
            </S.BodyInner>
            
            {error && 
              <S.SubmissionError className="submission-error">
                <span className="ion ion-alert-circled"></span>
                <div className="errorWrapper">
                  { _isObject(error) ? 
                    _map(error, (err) => {
                      return (
                        <li key={err}>{err}</li>
                      )
                    })
                    :
                    <span>{error}</span>
                  }
                </div>
              </S.SubmissionError>
            }
          </S.Body>

          <S.Footer>
            <S.SubmitBtn type="submit" disabled={submitting}>{translation('accountLimits_save')}</S.SubmitBtn>
          </S.Footer>

        </S.ResponsibleGamingForm>
    );
};

const mapStateToProps = (state) => {
    const {Customer: {customerLimits, limitOptions, isCustomerLimitsPending: isPending}} = state;

    return {
        customerLimits,
        isPending,
        limitOptions
    }
};

const mapDispatchToProps = (dispatch) => {
    const actionsToBind = {updateCustomerLimits, loadCustomerLimits, loadCustomerLimitsOptions, acceptRegulations};
    return {
        ...bindActionCreators(actionsToBind, dispatch),
        destroyLimitFormOnUnload: () => {
            dispatch(destroy('responsibleGamingForm'));
        }
    }
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'responsibleGamingForm',
        enableReinitialize: true,
        validate
    })
)(ResponsibleGamingForm);