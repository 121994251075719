/* global app */
import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { SBPayments } from 'sb-payments-totalbet'

const { Payments } = SBPayments

const AccountDeposit = ({ isLogged }) => {
  const lang = useMemo(() => `lang_${app.config.lang}_${app.config.mainCountryCode}_1_2`.toLowerCase(), [
    app.config.lang, app.config.mainCountryCode
  ])

  return (
    isLogged && (
      <Payments lang={lang} />
    )
  )
}

const mapStateToProps = ({ Auth: { isLogged } }) => {
  return {
    isLogged
  }
}

export default connect(mapStateToProps)(AccountDeposit)