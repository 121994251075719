import styled from 'styled-components';
import {Button} from 'global/styles/styles.js';

const S = {};

const RegisterStepColor = ({ theme: { $registerFormStepColor } }) => `${$registerFormStepColor}`;


S.StepsLineWrapper = styled.div`
    & .stepsContainer {
        display: flex;
        justify-content: center;
        padding: 1rem 0;
    }

    & .step {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 8.75rem;

        & .stepIcon {
            width: 5rem;
            height: 5rem;
        }
        & .stepTitle {
            margin-top: 0.31rem;
            font-size: 1.06rem;
            color: ${RegisterStepColor};
        }
    }

    & .separator {
        align-self: center;
        margin: 0 0 1rem;
        width: 3rem;
        &:after {
            content: "";
            display: block;
            border-bottom: 0.19rem solid ${RegisterStepColor};
        }
    }
`;

export default S;