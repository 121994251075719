import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import { Field, SubmissionError, reduxForm, change, getFormValues, getFormSyncErrors, getFormAsyncErrors } from 'redux-form';
import S from 'StyledRegisterWizardForm.js';
import Z from 'StyledRegisterFormByMyId.js';
import { translation, redirect, pushCustomEventToGoogleAnalytics } from 'helpers/utilsHelper.js';
import { isValueUnique } from 'authActions.js';
import useToggle from 'UseToggle.js';
import classNames from 'classnames';
import validation from 'sb-validate-js/validate.js';
import validators from 'validators.enum.js';
import store from 'store';
import TogglerIcon from 'register-tooltip-icon.svg';
import EyeIcon from 'register-eye-icon.svg';
import MaskedInput from 'react-maskedinput';
import ModalOpacity from 'ModalOpacity.js';
import CheckIcon from 'green-tick.svg';
import RegisterByMyIdAttributes from 'registerByMyIdAttributes.enum.js';


const validate = values => {
    const errors = validation(values, validators.REGISTER_BY_MY_ID_STEP2);
    return errors;
};

const asyncValidate = async (values, dispatch, props, field) => {
    const previousErrors = props.asyncErrors;
    if (field) {
        const fieldName = field;
        const fieldValue = values[field];
        try {
            const result = await dispatch(isValueUnique(fieldName, fieldValue));
            if (previousErrors) {
                throw 'prevErrors';
            } else {
                return result;
            }
        } catch (err) {
            if (err === 'prevErrors') {
                throw previousErrors;
            } else {
                throw Object.assign({}, previousErrors, { [fieldName]: [translation("account_registerForm_" + err)]});
            }
        }
    }
}


const PasswordToggler = () => {
    const [passwordVisibility, togglePassword] = useToggle(false);

    useEffect(() => {
        const passwordFields = document.querySelectorAll('[name="password"]');
        if (passwordFields.length) {
            const passwordFieldsDOM = [...passwordFields];
            if (passwordVisibility) {
                passwordFieldsDOM.forEach(el => el.type = 'text');
            } else {
                passwordFieldsDOM.forEach(el => el.type = 'password');
            }
        }
    }, [passwordVisibility]);

    return (
        <S.PasswordToggler onClick={togglePassword}>
            <S.PasswordTogglerIcon isActive={passwordVisibility} dangerouslySetInnerHTML={{ __html: EyeIcon }}></S.PasswordTogglerIcon>
        </S.PasswordToggler>
    );
};

const TooltipToggler = ({openTooltip, name, className}) => {
    return (
        <S.TooltipToggler onClick={() => openTooltip(name) } className={className}>
            <S.TooltipTogglerIcon dangerouslySetInnerHTML={{ __html: TogglerIcon }}></S.TooltipTogglerIcon>
        </S.TooltipToggler>
    );
};

const renderField = ({ input, label, focusLabel, required, type, disabled, openTooltip, preventAutoComplete, meta: { touched, error, warning, valid, asyncValidating, submitFailed } }) => {

    const isDisabled =  (typeof disabled != 'undefined') ? disabled : false;
    const inputClass = classNames({
        'async-validating': asyncValidating,
        'has-error': ((touched || submitFailed) && error),
        'is-valid': (!submitFailed && valid),
        'has-prefix-mobile': (input.name == 'mobile'),
        'disabled-input': disabled
    });


    return (
        <S.Row>
            <S.InputWrapper>
                {input.name == 'password' && <PasswordToggler />}
                {openTooltip && <TooltipToggler name={input.name} openTooltip={openTooltip}/>}

                {(input.name == 'mobile' ?
                    <>
                        <span className="prefix-mobile">+48</span>
                        <S.Input {...input}
                            type={type}
                            className={inputClass}
                            autoComplete={preventAutoComplete && "new-password"}
                            disabled={isDisabled}
                            as={MaskedInput}
                            mask="111-111-111"
                            format="###-###-###"
                            placeholder="___-___-___"
                        />
                    </> :
                    (input.name == 'password' ?
                        <S.Input {...input}
                            type={type}
                            required="required"
                            className={inputClass}
                            autoComplete={preventAutoComplete && "new-password"}
                            disabled={isDisabled}
                            onChange={input.onBlur}
                        /> :
                        <S.Input {...input}
                            type={type}
                            required="required"
                            className={inputClass}
                            autoComplete={preventAutoComplete && "new-password"}
                            disabled={isDisabled}
                        />
                    )
                )}
                <S.Label>
                    {(label && <span className="bottomLabel" dangerouslySetInnerHTML={{ __html: label }} ></span>)}
                    {(focusLabel && <span className="topLabel" dangerouslySetInnerHTML={{ __html: focusLabel }} ></span>)}
                </S.Label>
                {(input.name == 'password' ?
                    <PasswordStrengthChecker error={error} touched={touched}/> :
                    (touched || submitFailed) && ((error && <S.Error>{error[0]}</S.Error>) || (warning &&
                            <S.Error>{warning}</S.Error>))
                )}

                {((input.name == 'email' || input.name == 'mobile') && 
                    <Z.InputIconCheckedContainer>
                        {(valid && <Z.InputIconChecked dangerouslySetInnerHTML={{ __html: CheckIcon }} ></Z.InputIconChecked>)}
                    </Z.InputIconCheckedContainer>
                )}

            </S.InputWrapper>
        </S.Row>
    );
};


const PasswordStrengthChecker = ({error, touched}) => {
    return (
        <S.PasswordStrengthChecker>
            <PasswordStrengthField name="length" error={error} touched={touched}/>
            <PasswordStrengthField name="lowerCase" error={error} touched={touched}/>
            <PasswordStrengthField name="upperCase" error={error} touched={touched}/>
            <PasswordStrengthField name="digit" error={error} touched={touched} />
        </S.PasswordStrengthChecker>
    )
};

const PasswordStrengthField = ({ error, name, touched }) => {
    const fieldClass = classNames({
        'has-error': (touched && error && error[0] && name in error[0]),
        'no-touched': !touched
    });
    return (
        <S.PasswordField name={name} className={fieldClass} dangerouslySetInnerHTML={{ __html: translation(`account_registerForm_${name}PasswordStrength`) }}></S.PasswordField>
    )
};

const renderCheckbox = ({ input, label, required, indentation, openTooltip, meta: { touched, error, warning, valid, submitFailed } }) => {

    let [fullText, setFullText] = useState(false);

    const inputClass = classNames({
        'has-error': ((touched || submitFailed) && error),
        'is-valid': ((touched || submitFailed) && valid)
    });

    const checkboxWrapperClass = classNames({
        'with-checkbox': true,
        'indentation': indentation
    })

    const cutHTMLRegex = /<[^>]*>/g;
    const htmlTags = label.match(cutHTMLRegex);
    let htmlTagsLength = 0;
    

    if(htmlTags) {
        for(let htmlTag of htmlTags) {
            htmlTagsLength += htmlTag.length
        }
    }  

    const lengthToCut = 104 + htmlTagsLength;

    const toggleFullText = () => {

        if (label.length <= lengthToCut) {
            return;
        } else {
            setFullText(!fullText);
        }
    };

    const createLabelMarkup = () => {
        let more;
        let currLabel;

        if (!fullText && label.length > lengthToCut) {
            currLabel = label.slice(0, lengthToCut);
            more = `<span>(${translation('account_registerForm_showMore')})</span>`;
        } else if (label.length <= lengthToCut) {
            currLabel = label;
            more = '';
        } else {
            currLabel = label;
            more = `<span>(${translation('account_registerForm_showLess')})</span>`;
        }
        return { __html: `<span>${currLabel}</span>${more}` }
    };

    const toggleCheckbox = (event) => {
        if (!event.target.checked) {
            const { dispatch } = store;
            dispatch(change('registerByMyIdForm', 'selectAllFields_step1', false));
        }
        input.onChange(event.target.checked);
    };

    return (
        <S.Row>
            <S.CheckboxWrapper className={checkboxWrapperClass} name={input.name}>
                {openTooltip && <TooltipToggler name={input.name} openTooltip={openTooltip} className="checkbox-toggler"/>}
                <S.Input {...input}
                        value={input.value}
                        checked={input.value}
                        onChange={event  => toggleCheckbox(event)} type="checkbox" required={required} 
                />
                <S.FakeChbox
                    className={inputClass}
                >
                    {input.value && <S.CheckMark></S.CheckMark>}
                </S.FakeChbox>

                <label
                    dangerouslySetInnerHTML={createLabelMarkup()}
                    onClick={toggleFullText}
                />

                {(touched || submitFailed) && ((error && <S.Error>{translation(error[0])}</S.Error>) || (warning &&
                <S.Error>{warning}</S.Error>))}
            </S.CheckboxWrapper>
        </S.Row>
    );
};

const additionalInfoMarkup = (text) => {
    let [fullText, setFullText] = useState(false);

    const toggleFullText = () => {

        if (text.length <= 100) {
            return;
        } else {
            setFullText(!fullText);
        }
    };

    const createLabelMarkup = () => {
        let more;
        let currLabel;
        if (!fullText && text.length > 100) {
            currLabel = text.slice(0, 100);
            more = `<span>${translation('account_registerForm_showMore')}</span>`;
        } else if (text.length <= 100) {
            currLabel = text;
            more = '';
        } else {
            currLabel = text;
            more = `<span>${translation('account_registerForm_showLess')}</span>`;
        }
        return { __html: `<span>${currLabel}</span>${more}` };
    };

    return (
        <S.AdditionalInfoGrey dangerouslySetInnerHTML={createLabelMarkup()} onClick={toggleFullText}></S.AdditionalInfoGrey>
    )
}

const normalizeMobile = value => value && value.replace(/[\s\-]/g, '');
const normalizePassword = value => value && value.replace(/[\s]/g, '');
const normalizePromoCode = value => value && value.replace(/[\s]/g,'').toUpperCase();

const handleSelectAllFields = (event, newValue, prevValue) => {
    const { dispatch, getState } = store;
    const {form: {registerByMyIdForm: {registeredFields}}} = getState();
    if(newValue && newValue != prevValue) {
        dispatch(change('registerByMyIdForm', 'marketingConsent', true));
        dispatch(change('registerByMyIdForm', 'rodoClause', true));
        dispatch(change('registerByMyIdForm', 'smsConsent', true));
        dispatch(change('registerByMyIdForm', 'emailConsent', true));
        if (registeredFields['isBonusConsent0']){
            dispatch(change('registerByMyIdForm', 'isBonusConsent0', true));
        }
        if (registeredFields['isBonusConsent1']){
            dispatch(change('registerByMyIdForm', 'isBonusConsent1', true));
        }
        if (registeredFields['isBonusConsent2']){
            dispatch(change('registerByMyIdForm', 'isBonusConsent2', true));
        }
    } else {
        dispatch(change('registerByMyIdForm', 'marketingConsent', false));
        dispatch(change('registerByMyIdForm', 'rodoClause', false));
        dispatch(change('registerByMyIdForm', 'smsConsent', false));
        dispatch(change('registerByMyIdForm', 'emailConsent', false));
        if (registeredFields['isBonusConsent0']){
            dispatch(change('registerByMyIdForm', 'isBonusConsent0', false));
        }
        if (registeredFields['isBonusConsent1']){
            dispatch(change('registerByMyIdForm', 'isBonusConsent1', false));
        }
        if (registeredFields['isBonusConsent2']){
            dispatch(change('registerByMyIdForm', 'isBonusConsent2', false));
        }
    }
}

const RegisterFormByMyIdStep2 = ({ handleSubmit, onSubmit, submitting, submitInProgress, error, synchronousErrors, asynchronousErrors, checkErrorsForDataLayer, formValues, containers = [], inputsReadOnly }) => {

    const [tooltipTranslation, setTooltipTranslation] = useState('account_registerForm_tooltip');
    const [showTooltipModal, toggleTooltipModal] = useToggle(false);
    
    const bonusesVisibleForPlayer = containers.filter((consent) => consent.visibleForPlayer && consent.container == 'OFFERS');

    useEffect(() => {
        pushCustomEventToGoogleAnalytics({ 'event':'register-myid-2step'});
    }, []);

    useEffect(() => {
        document.getElementsByClassName('main-container').scrollTop = 0;
    }, []);

    useEffect(() => {
        const { dispatch } = store;
        if(formValues?.marketingConsent && !formValues?.emailConsent && !formValues?.smsConsent) {
            dispatch(change('registerByMyIdForm', 'smsConsent', true));
            dispatch(change('registerByMyIdForm', 'emailConsent', true));
        } else if(!formValues?.marketingConsent) {
            dispatch(change('registerByMyIdForm', 'smsConsent', false));
            dispatch(change('registerByMyIdForm', 'emailConsent', false));
        }
    }, [formValues?.marketingConsent]);

    useEffect(() => {
        const { dispatch } = store;
        if(formValues?.emailConsent || formValues?.smsConsent ) {
            dispatch(change('registerByMyIdForm', 'marketingConsent', true));
        } else if(!formValues?.emailConsent && !formValues?.smsConsent) {
            dispatch(change('registerByMyIdForm', 'marketingConsent', false));
        }
    }, [formValues?.smsConsent, formValues?.emailConsent]);


    const openTooltip = (fieldName = '') => {
        const tooltipTranslation = `account_registerForm_${fieldName}Tooltip`;
        setTooltipTranslation(tooltipTranslation);
        toggleTooltipModal();
    };

    const submit = async () => {

        const { dispatch } = store;

        try {
            await dispatch(isValueUnique("email", formValues.email));
        } catch (error) {
            throw new SubmissionError({ email: [translation(error)] });
        }
        try {
            await dispatch(isValueUnique("mobile", formValues.mobile));
        } catch (error) {
            throw new SubmissionError({ mobile: [translation(error)] });
        }

        onSubmit();
    };

    return (
        <>

            <ModalOpacity isOpen={showTooltipModal}
                toggleOpen={toggleTooltipModal}
                title="Tooltip"
                showHeader={false}
                padding={0}
                className="modal-opacity-transparent"
                modalBackgroundTransparent={true}>

                <Z.TooltipModal>
                    <Z.TooltipModalCloseBtn
                        onClick={toggleTooltipModal}>{translation('common_close')}</Z.TooltipModalCloseBtn>
                    <Z.TooltipModalMsg className="registerByMyId" dangerouslySetInnerHTML={{ __html: translation(`${tooltipTranslation}`) }} ></Z.TooltipModalMsg>
                </Z.TooltipModal>

            </ModalOpacity>
       
            <S.RegisterWizardFormStep1 className="register-step1" onSubmit={handleSubmit(submit)} autoComplete="off" noValidate>

                    <Field
                        name="email"
                        type="text"
                        component={renderField}
                        label={translation('account_registerForm_emailByMyId')}
                        focusLabel={translation('account_registerForm_emailFocus')}
                        disabled= { inputsReadOnly.indexOf(RegisterByMyIdAttributes.EMAIL) > -1  }
                        preventAutoComplete={true}
                    />

                    <Field
                        name="password"
                        type="password"
                        component={renderField}
                        label={translation('account_registerForm_password')}
                        focusLabel={translation('account_registerForm_passwordFocus')}
                        required={true}
                        disabled={false}
                        normalize={normalizePassword}
                        preventAutoComplete={true}
                    />
                    <Field
                        name="mobile"
                        type="text"
                        component={renderField}
                        label={translation('account_registerForm_phoneNumberByMyId')}
                        focusLabel={translation('account_registerForm_phoneNumberFocus')}
                        normalize={normalizeMobile}
                        disabled= { inputsReadOnly.indexOf(RegisterByMyIdAttributes.MOBILE) > -1  }
                    />
                    <Field
                        name="promoCode"
                        type="text"
                        component={renderField}
                        label={translation('account_registerForm_promoCode')}
                        focusLabel={translation('account_registerForm_promoCodeFocus')}
                        normalize={normalizePromoCode}
                        required={false}
                    />

                    <Field
                        name="selectAllFields_step1"
                        component={renderCheckbox}
                        label={translation('account_registerForm_marketing_selectAll')}
                        required={false}
                        onChange={handleSelectAllFields}
                    />

                    <Field
                        name="rodoClause"
                        component={renderCheckbox}
                        type="checkbox"
                        label={translation('account_registerForm_acceptRules')}
                        required={true}
                    />

                    <Field
                        name="marketingConsent"
                        component={renderCheckbox}
                        type="checkbox"
                        label={translation('account_registerForm_marketing_marketingConsent')}
                        required={false}
                    />

                    <Field
                        name="smsConsent"
                        component={renderCheckbox}
                        type="checkbox"
                        label={translation('account_registerForm_marketing_smsConsent')}
                        required={false}
                        indentation={true}
                    />

                    <Field
                        name="emailConsent"
                        component={renderCheckbox}
                        type="checkbox"
                        label={translation('account_registerForm_marketing_emailConsent')}
                        required={false}
                        indentation={true}
                    />
                   
                    {bonusesVisibleForPlayer.map((bonus, index) =>
                        <Field
                            name={'isBonusConsent' + index}
                            key={index}
                            component={renderCheckbox}
                            type="checkbox"
                            label={translation('account_registerForm_isBonus'+ bonus.definitionId)}
                            required={false}
                            openTooltip={openTooltip}
                        />
                    )}
                    

                    {additionalInfoMarkup(translation('account_registerForm_rodoClause'))}


                    <S.SubmitButton type="submit" className="next" disabled={submitting||submitInProgress} onClick={() => checkErrorsForDataLayer(synchronousErrors, asynchronousErrors)}>{translation('account_registerForm_registerByMyIdBtn')}</S.SubmitButton>

                    <S.AdditionalInfoRegByMyId>{translation('account_registerForm_contact')}</S.AdditionalInfoRegByMyId>
                    <S.LinkHelp to='/pages/pomoc' onClick={() => chatButton.onClick()}>{translation('account_registerForm_contactLink')}</S.LinkHelp>

            </S.RegisterWizardFormStep1>

        </>
    )
}

let RegisterFormByMyId2 = reduxForm({
    form: 'registerByMyIdForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
    asyncValidate,
    asyncBlurFields: ['email', 'mobile']
})(RegisterFormByMyIdStep2);

const mapStateToProps = (state) => {
    return {
        synchronousErrors: getFormSyncErrors('registerByMyIdForm')(state),
        asynchronousErrors: getFormAsyncErrors('registerByMyIdForm')(state),
        formValues: getFormValues('registerByMyIdForm')(state)
    }
}

RegisterFormByMyId2 = connect(mapStateToProps, null)(RegisterFormByMyId2);


export default RegisterFormByMyId2;
