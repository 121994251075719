import React, { useMemo, useState, useRef, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm, formValues, change, getFormSyncErrors, getFormValues } from 'redux-form';
import MaskedInput from 'react-maskedinput';
import Select from 'react-select';
import classNames from 'classnames';
import store from 'store';
import _find from 'lodash/find';
import _map from 'lodash/map';
import validation from 'sb-validate-js/validate.js';
import { verificationCode, smsCodeVerify } from 'authActions.js';
import validators from 'validators.enum.js';
import { translation } from 'utilsHelper.js';
import Loader from 'Loader.js';
import { TooltipToggler } from 'RegisterWizardForm.js';
import S from 'StyledRegisterWizardForm.js';
import BackBtnArrowIcon from 'back-btn-icon.svg';

const validate = (values) => {
    const errors = validation(values, validators.REGISTER_STEP2);
    return errors;
};

const shouldAsyncValidate = ({trigger, syncValidationPasses}) =>{
    if (!syncValidationPasses) {
        return false
    }
    
    switch (trigger) {
        case 'blur':
        case 'change':
        case 'submit':
            return true;
        default:
            return false
    }
};

const asyncValidate = async (values, dispatch, props, field) => {
    const previousErrors = props.asyncErrors;
    if (field) {
        const fieldName = field;
        const fieldValue = values[field];
        try {
            const result = await dispatch(smsCodeVerify(fieldName, fieldValue, values['mobile']));
            if (previousErrors) {
                throw 'prevErrors';
            } else {
                return result;
            }
        } catch (err) {
            if (err === 'prevErrors') {
                throw previousErrors;
            } else {
                throw Object.assign({}, previousErrors, { [fieldName]: [translation("account_registerForm_" + err)]});
            }
        }
    }
}


const renderField = ({
    input,
    label,
    type,
    focusLabel,
    info,
    openTooltip,
    meta: { touched, error, warning, valid, submitFailed }
}) => {
    const inputClass = classNames({
        'has-error': ((touched || submitFailed) && error),
        'is-valid': ((touched || submitFailed) && valid)
    });

    return (
        <S.Row>

            <S.InputWrapper>
                {openTooltip && <TooltipToggler name={input.name}/>}

                {(input.name == 'dateOfBirth' ? 
                    <S.Input {...input}
                        type={type}
                        required="required"
                        className={inputClass}
                        as={MaskedInput}
                        mask="**/**/****"
                        placeholder="DD/MM/RRRR"
                        onChange={(event) => {
                            if(event.target.value) {                         
                                const selectionStart = event.target.selectionStart;
                                const selectionEnd = event.target.selectionEnd;

                                setTimeout((elem, input) => {
                                    var splittedSlash = elem.value.split('/');

                                    splittedSlash[0] = splittedSlash[0].replaceAll('_', 'D');
                                    splittedSlash[1] = splittedSlash[1].replaceAll('_', 'M');
                                    splittedSlash[2] = splittedSlash[2].replaceAll('_', 'R');

                                    const finalString = splittedSlash.join("/");

                                    input.onChange(finalString);

                                    elem.selectionStart = selectionStart;
                                    elem.selectionEnd = selectionEnd;
                                }, 5, event.target, input)
                            }
                        }}
                    /> : 
                    (input.name == 'personalIdentifier' ? 
                        <S.Input {...input}
                            type={type}
                            required="required"
                            className={inputClass}
                            as={MaskedInput}
                            mask="11111111111"
                            placeholder="___________"
                        /> :     
                    (input.name == 'verificationCode' ? 
                            <S.Input {...input}
                            type={type}
                            required="required"
                            className={inputClass}
                            as={MaskedInput}
                            mask="111111"
                            placeholder="______"
                        />
                        :
                        <S.Input {...input}
                            type={type}
                            required="required"
                            className={inputClass}
                        />
                    ))
                    )}

                <S.Label>
                    {(label && <span className="bottomLabel" dangerouslySetInnerHTML={{ __html: label }} ></span>)}
                    {(focusLabel && <span className="topLabel" dangerouslySetInnerHTML={{ __html: focusLabel }} ></span>)}
                </S.Label>

                {(touched || submitFailed) && ((error && <S.Error className="text-ellipsis">{error[0]}</S.Error>) || (warning &&
                    <S.Error>{warning}</S.Error>))}
                {info &&
                <S.Info>
                    <span dangerouslySetInnerHTML={{ __html: info }} ></span>
                </S.Info>}

            </S.InputWrapper>
        </S.Row>
    )
};

const renderCheckbox = ({
    input, 
    label, 
    required, 
    openTooltip, 
    meta: { touched, error, warning, valid, submitFailed } 
}) => {

    let [fullText, setFullText] = useState(false);

    const inputClass = classNames({
        'has-error': ((touched || submitFailed) && error),
        'is-valid': ((touched || submitFailed) && valid)
    });

    const toggleFullText = () => {

        if (label.length <= 100) {
            return;
        } else {
            setFullText(!fullText);
        }
    };

    const createLabelMarkup = () => {
        let more;
        let currLabel;
        if (!fullText && label.length > 100) {
            currLabel = label.slice(0, 100);
            more = `<span>(${translation('account_registerForm_showMore')})</span>`;
        } else if (label.length <= 100) {
            currLabel = label;
            more = '';
        } else {
            currLabel = label;
            more = `<span>(${translation('account_registerForm_showLess')})</span>`;
        }
        return { __html: `<span>${currLabel}</span>${more}` };
    };

    const toggleCheckbox = (event) => {
        if (!event.target.checked) {
            const { dispatch } = store;
            dispatch(change('registerWizardForm', 'selectAllFields_step2', false));
        }
        input.onChange(event.target.checked);

    };

    return (
        <S.Row>
            <S.CheckboxWrapper className="with-checkbox" name={input.name}>
                {openTooltip && <TooltipToggler name={input.name} className="checkbox-toggler"/>}
                <S.Input {...input}
                        value={input.value}
                        checked={input.checked ? 'checked' : ''}
                        onChange={event => toggleCheckbox(event)} type="checkbox" required={required} 
                />
                <S.FakeChbox
                    className={inputClass}
                >
                    {input.value && <S.CheckMark></S.CheckMark>}
                </S.FakeChbox>

                <label
                    dangerouslySetInnerHTML={createLabelMarkup()} 
                    onClick={toggleFullText}
                />

                {(touched || submitFailed) && ((error && <S.Error>{translation(error[0])}</S.Error>) || (warning &&
                <S.Error>{warning}</S.Error>))}
            </S.CheckboxWrapper>
        </S.Row>
    );
};

const renderSelect = ({
    input,
    label,
    withLabel,
    options,
    className,
    meta: { touched, error, warning, valid, submitFailed }
}) => {

    const inputClass = classNames({
        'has-error': ((touched || submitFailed) && error),
        'is-valid': ((touched || submitFailed) && valid),
        'react-select-container': true
    });

    const inputWrapperClass = classNames('with-select', {[`${className}`]: Boolean(className)});
    return (
        <S.Row>
            <S.InputWrapper className={inputWrapperClass}>

                <S.Select as={Select}
                    options={options}
                    value={_find(options, {value: input.value})}
                    isSearchable={false}
                    classNamePrefix="react-select"
                    className={inputClass}
                    placeholder={label}
                    onChange={(props) => {
                        input.onChange(props.value);
                    }}
                />

                {withLabel && <S.Label>{label}</S.Label>} 

                {(touched || submitFailed) && ((error && <S.Error>{error[0]}</S.Error>) || (warning &&
                    <S.Error>{warning}</S.Error>))}

            </S.InputWrapper>
        </S.Row>
    )
};

let PeselToggler = ({ nationality }) => {

    return (nationality == 'PL' &&
        (
            <Field
                name="personalIdentifier"
                type="text"
                component={renderField}
                label={translation('account_registerForm_personalIdentifier')}
                focusLabel={translation('account_registerForm_personalIdentifierFocus')}
                openTooltip={true}
            />
        )
    )
};
PeselToggler = formValues('nationality')(PeselToggler);

let PassportToggler = ({ nationality }) => {

    return (nationality != 'PL' &&
        (
            <Field
                name="passportNumber"
                type="text"
                component={renderField}
                label={translation('account_registerForm_passportNumber')}
                focusLabel={translation('account_registerForm_passportNumberFocus')}
                openTooltip={true}
            />
        )
    )
};
PassportToggler = formValues('nationality')(PassportToggler);

let AddressToggler = ({ nationality }) => {
    return (nationality != 'PL' &&
        (
            <>
                <Field
                    name="city"
                    type="text"
                    component={renderField}
                    label={translation('register_city')}
                    focusLabel={translation('register_cityFocus')}
                />

                <Field
                    name="street"
                    type="text"
                    component={renderField}
                    label={translation('register_street')}
                    focusLabel={translation('register_streetFocus')}
                />

                <Field
                    name="postalCode"
                    type="text"
                    component={renderField}
                    label={translation('register_postalCode')}
                    focusLabel={translation('register_postalCodeFocus')}
                />
            </>
        )
    );
};
AddressToggler = formValues('nationality')(AddressToggler);

let DateOfBirthToggler = ({ nationality }) => {
    return (nationality != 'PL' && (
        <Field
            name="dateOfBirth"
            component={renderField}
            label={translation('account_registerForm_dateOfBirth')}
            focusLabel={translation('account_registerForm_dateOfBirthFocus')}
        />
    ));
};
DateOfBirthToggler = formValues('nationality')(DateOfBirthToggler);

let ResendVerficationCodeButton = ({mobile, submitting, verificationCode}) => {
    const COUNTDOWN_START = 30000; // ms
    const COUNTDOWN_END = 0 // ms;
    const COUNTDOWN_INTERVAL = 1000; // ms

    const [countdown, setCountdown] = useState(null);
    const [sendingCode, setSendingCode] = useState(false);
    const timer = useRef(null);

    const startCountdown = () => {
        const startTime = Date.now() + COUNTDOWN_START;
        setCountdown(startTime - Date.now());
        timer.current = setInterval(() => {
            setCountdown(() => {
                const leftTime = startTime - Date.now();
                if(leftTime <= COUNTDOWN_END){
                    stopCountdown();
                    return null;
                } else {
                    return leftTime;
                }})
        }, COUNTDOWN_INTERVAL);
    }

    const stopCountdown = () => {
        clearInterval(timer.current);
    }

    useEffect(() => {
        return () => stopCountdown()
    }, [])


    return <S.ResendVerificationCodeButton 
                disabled={submitting || sendingCode || countdown}
                data-test='account_registerForm_resendVerificationCode'
                onClick={ () =>  {
                            setSendingCode(true)
                            verificationCode({'mobile': mobile})
                                .then(() =>
                                    startCountdown()
                                )
                                .finally(() => {
                                    setSendingCode(false)
                                })
                        }}
                dangerouslySetInnerHTML={{ __html: countdown ? 
                    translation(
                        'account_registerForm_resendVerificationCode_wait', 
                        [Math.ceil(countdown/1000)])
                    : translation('account_registerForm_resendVerificationCode')}}/>
}
ResendVerficationCodeButton = formValues('mobile')(ResendVerficationCodeButton);

let RegisterWizardFormStep2 = ({
    handleSubmit, 
    goToPrevPage, 
    submitting, 
    checkErrorsForDataLayer, 
    synchronousErrors, 
    formValues, 
    verificationCode,
    containers = []
}) => {

    const bonusesVisibleForPlayer = containers.filter((container) => container.visibleForPlayer);

    const countries = useMemo(() => {
        const countries = app.service.Language.cachedDict.countries;
        const options = _map(countries, (countryName, countryCode) => {
            const option = {};
            option['label'] = countryName;
            option['value'] = countryCode;
            return option;
        });

        options.sort((a, b) => a['label'].localeCompare(b['label']));
        return options;
    }, []);


    return (
        <S.RegisterWizardFormStep2 onSubmit={handleSubmit} noValidate>

            {submitting && (<S.Cover><Loader color="#F05A22"/></S.Cover>)}

            <S.PrevStepBtn onClick={goToPrevPage}>
                <S.PrevIcon dangerouslySetInnerHTML={{ __html: BackBtnArrowIcon }}></S.PrevIcon>
            </S.PrevStepBtn>

            <Field
                name="firstName"
                type="text"
                component={renderField}
                label={translation('account_registerForm_firstName')}
                focusLabel={translation('account_registerForm_firstNameFocus')}
            />

            <Field
                name="lastName"
                type="text"
                component={renderField}
                label={translation('account_registerForm_lastName')}
                focusLabel={translation('account_registerForm_lastNameFocus')}
            />

            <Field
                name="nationality"
                component={renderSelect}
                withLabel={true}
                options={countries}
                label={translation('account_registerForm_nationality')}
            />

            <AddressToggler/>

            <DateOfBirthToggler/>

            <PassportToggler/>

            <PeselToggler/>

            <Field
                name="verificationCode"
                component={renderField}
                label={translation('account_registerForm_verificationCode')}
                focusLabel={translation('account_registerForm_verificationCodeFocus')}
                info={translation('account_registerForm_verificationCodeInfo', [formValues?.mobile])}
                openTooltip={true}
            />

            {bonusesVisibleForPlayer.map((bonus, index) =>
            <Field
                name={'isBonusConsent' + index}
                key={index}
                component={renderCheckbox}
                type="checkbox"
                label={translation('account_registerForm_isBonus'+ bonus.definitionId)}
                required={false}
                openTooltip={true}
            />
            )}

            <ResendVerficationCodeButton submitting={submitting} verificationCode={verificationCode}/>

            <S.SubmitButton type="submit" disabled={submitting}
                data-test='account_registerForm_signup'
                onClick={() => checkErrorsForDataLayer(synchronousErrors, formValues)}>
                {translation('account_registerForm_signup')}
            </S.SubmitButton>

            <S.AdditionalInfo onClick={() => {
                chatButton.onClick()
            }}>
                {translation('account_registerForm_needHelp')}
            </S.AdditionalInfo>

        </S.RegisterWizardFormStep2>
    );
};

let RegisterWizardForm2 = reduxForm({
    form: 'registerWizardForm',
    validate,
    asyncValidate,
    asyncBlurFields: ['verificationCode'],
    shouldAsyncValidate,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
})(RegisterWizardFormStep2);

const mapStateToProps = (state) => {
    return {
        synchronousErrors: getFormSyncErrors('registerWizardForm')(state),
        formValues: getFormValues('registerWizardForm')(state)
    }
}
const mapDispatchToProps = (dispatch) => {
    const bindedActions = {
        verificationCode
    }

    return {
        ...bindActionCreators(bindedActions, dispatch)
    }
}
RegisterWizardForm2 = connect(mapStateToProps, mapDispatchToProps)(RegisterWizardForm2);

export default RegisterWizardForm2;