import _get from 'lodash/get';
import _find from 'lodash/find';
import _size from 'lodash/size';
import memoize from 'fast-memoize';

// actions name
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const REMOVE_LEAGUE_BY_ID = 'REMOVE_LEAGUE_BY_ID';
export const ADD_LEAGUE_BY_ID = 'ADD_LEAGUE_BY_ID';
export const CHANGE_SPORT_ID = 'CHANGE_SPORT_ID';
export const SET_CATEGORIES_BY_TIME = 'SET_CATEGORIES_BY_TIME';
export const CHANGE_TIME_FILTER = 'CHANGE_TIME_FILTER';
export const SELECT_LEAGUE_FOR_SPORT = 'SELECT_LEAGUE_FOR_SPORT';
export const UNSELECT_LEAGUE_FOR_SPORT = 'UNSELECT_LEAGUE_FOR_SPORT';
export const CHANGE_SPORT_ORDER = 'CHANGE_SPORT_ORDER';
export const SELECT_LEAGUES_GROUP_BY_SPORT = 'SELECT_LEAGUES_GROUP_BY_SPORT';
export const REMOVE_SPORT_BY_ID = 'REMOVE_SPORT_BY_ID';
export const FILTER_LEFT_MENU_ESPORT_CATEGORIES = 'FILTER_LEFT_MENU_ESPORT_CATEGORIES';
export const FETCH_POPULAR_CATEGORIES = 'FETCH_POPULAR_CATEGORIES';
export const SELECT_THIRD_LEVEL_CATEGORY = 'SELECT_THIRD_LEVEL_CATEGORY';
export const RESET_SLIP_LIST = 'RESET_SLIP_LIST';
export const LOAD_CATEGORIES_FOR_MARKET_GROUP_TYPE_ID = 'LOAD_CATEGORIES_FOR_MARKET_GROUP_TYPE_ID';

const isOutright = (categoryId, categories) => {
    const league = _find(categories, {categoryId});
    const treatAsSport = _get(league, 'treatAsSport');
    return treatAsSport == -500;
};

const isOutrightMemoized = memoize(isOutright);

// actions creators
const changeTimeFilter = (selectedTime) => {
    return async(dispatch, getState, {CategoriesApi}) => {
        try {
            const {Categories:{time}} = getState();
            const isSameTimeSelected = time == selectedTime;
            if (!isSameTimeSelected) {
                dispatch(changeTimeFilterPending());

                // !!
                const {Categories:{selectedLeaguesBySport:selectedLeagues}} = getState();
                app.events.broadcast(app.enumerator.Events.ON_REDUX_SELECTED_CATEGORIES_CHANGE, {selectedLeagues});

                const {code, data} = await CategoriesApi.getCategoriesByTime(selectedTime);
                if (code == 200) {
                    dispatch(setCategoriesByTime(selectedTime, data));
                    dispatch(selectTime(selectedTime));

                    const {Categories:{selectedLeaguesBySport:selectedLeagues}} = getState();
                    app.events.broadcast(app.enumerator.Events.ON_REDUX_SELECTED_CATEGORIES_CHANGE, {selectedLeagues});
                    return data;
                } else {
                    throw {message: data};
                }
            } else {
                dispatch(selectTime());

                const {Categories:{selectedLeaguesBySport:selectedLeagues}} = getState();
                app.events.broadcast(app.enumerator.Events.ON_REDUX_SELECTED_CATEGORIES_CHANGE, {selectedLeagues});
            }
        } catch ({message}) {
            dispatch(changeTimeFilterFailure(message));
            console.log({message})
        }
    }
};

const selectTime = (time = null) => {
    return {
        type: CHANGE_TIME_FILTER,
        payload: {
            time
        }
    };
};

const selectThirdLevelCategory = (thirdLevelSelectedLeague) => {
    return {
        type: SELECT_THIRD_LEVEL_CATEGORY,
        payload: {
            thirdLevelSelectedLeague
        }
    };
};


const setCategoriesByTime = (time, categories) => {
    return {
        type: SET_CATEGORIES_BY_TIME,
        payload: {
            time,
            categories
        }
    };
};


const fetchCategories = () => {
    return async(dispatch, getState, {CategoriesApi}) => {

        dispatch(fetchCategoriesPending());

        try {
            const {data} = await CategoriesApi.getAllCategories();
            if(!_size(data)){
                throw 'empty categories array!';
            }

            dispatch(fetchCategoriesSuccess(data));
            return data;

        } catch (error) {
            dispatch(fetchCategoriesFailure(error));
            throw error;
        }
    }
};

const fetchCategoriesFailure = (error) => {
    return {
        type: `${FETCH_CATEGORIES}_FAILURE`,
        payload: {error}
    }
};

const fetchCategoriesSuccess = (categories) => {
    return {
        type: `${FETCH_CATEGORIES}_SUCCESS`,
        payload: {categories}
    }
};

const fetchCategoriesPending = () => {
    return {
        type: `${FETCH_CATEGORIES}_PENDING`
    }
};

const fetchPopularCategories = () => {
    return async(dispatch, getState, {CategoriesApi}) => {

        dispatch(fetchPopularCategoriesPending());

        try {
            const {data} = await CategoriesApi.getPopularCategories();
            if(!_size(data)){
                throw 'empty popular categories array!'
            }

            dispatch(fetchPopularCategoriesSuccess(data));
            return data;

        } catch (error) {
            dispatch(fetchPopularCategoriesFailure(error));
            throw error;
        }
    }
};

const fetchPopularCategoriesFailure = (error) => {
    return {
        type: `${FETCH_POPULAR_CATEGORIES}_FAILURE`,
        payload: {error}
    }
};

const fetchPopularCategoriesSuccess = (popularCategories) => {
    return {
        type: `${FETCH_POPULAR_CATEGORIES}_SUCCESS`,
        payload: {popularCategories}
    }
};

const fetchPopularCategoriesPending = () => {
    return {
        type: `${FETCH_POPULAR_CATEGORIES}_PENDING`
    }
};

const changeTimeFilterFailure = (error) => {
    return {
        type: `${CHANGE_TIME_FILTER}_FAILURE`,
        payload: {error}
    }
};

const changeTimeFilterPending = () => {
    return {
        type: `${CHANGE_TIME_FILTER}_PENDING`
    }
};


const selectLeagueById = (leagueId) => {
    return {
        type: `${ADD_LEAGUE_BY_ID}`,
        payload: {leagueId}
    }
};

const deselectLeagueById = (leagueId) => {
    return {
        type: `${REMOVE_LEAGUE_BY_ID}`,
        payload: {leagueId}
    }
};

const changeSportId = (prevSortId) => {
    return {
        type: `${CHANGE_SPORT_ID}`,
        payload: {prevSortId}
    }
};

const selectLeagueForSport = (leagueId, sportId) => {
    return {
        type: `${SELECT_LEAGUE_FOR_SPORT}`,
        payload: {leagueId, sportId}
    }
};

const unselectLeagueForSport = (leagueId, sportId) => {
    return {
        type: `${UNSELECT_LEAGUE_FOR_SPORT}`,
        payload: {leagueId, sportId}
    }
};

const changeSportOrder = (sportId) => {
    return {
        type: `${CHANGE_SPORT_ORDER}`,
        payload: {sportId}
    }
};

const removeSportById = (sportId) => {
    return (dispatch, getState) => {
        dispatch({type: `${REMOVE_SPORT_BY_ID}`, payload: {sportId}});
        const {Categories:{selectedLeaguesBySport:selectedLeagues}} = getState();
        app.events.broadcast(app.enumerator.Events.ON_REDUX_SELECTED_CATEGORIES_CHANGE, {selectedLeagues});
    }
};

const toggleSelectedLeaguesForSports = (leaguesGroupBySport) => {
    return (dispatch, getState) => {
        dispatch({type: `${SELECT_LEAGUES_GROUP_BY_SPORT}`, payload: {leaguesGroupBySport}});

        const selectedLeagues = _get(getState(), 'Categories.selectedLeaguesBySport');
        app.events.broadcast(app.enumerator.Events.ON_REDUX_SELECTED_CATEGORIES_CHANGE, {selectedLeagues});
    }
};
const resetSlipList =()=>{
    return{
        type:`${RESET_SLIP_LIST}`
    }
}

const toggleSelectedLeague = (leagueId, sportId) => {
    return (dispatch, getState) => {
        const {Categories:{selectedLeaguesBySport, outrights, categories}} = getState();
        let indexOfLeague = null;

        if (Array.isArray(leagueId)) {
            const selectedCategoriesForSport = _get(selectedLeaguesBySport, [sportId], []);
            indexOfLeague = selectedCategoriesForSport.concat(outrights).every(item=>leagueId.indexOf(item)) ? -1 : 0;
        } else {
            const isOutright = isOutrightMemoized(leagueId, categories);
            const selectedCategoriesForSport = _get(selectedLeaguesBySport, [sportId], []);

            if (isOutright) {
                indexOfLeague = outrights.indexOf(leagueId);
            } else {
                indexOfLeague = selectedCategoriesForSport.indexOf(leagueId);
            }
        }

        if (indexOfLeague != -1) {
            dispatch(unselectLeagueForSport(leagueId, sportId));
        } else {
            dispatch(selectLeagueForSport(leagueId, sportId));
        }

        const selectedLeagues = _get(getState(), 'Categories.selectedLeaguesBySport');
        app.events.broadcast(app.enumerator.Events.ON_REDUX_SELECTED_CATEGORIES_CHANGE, {selectedLeagues});
    }
};

const filterLeftMenuEsportCategories = (isEsport) => {
    return {
        type: FILTER_LEFT_MENU_ESPORT_CATEGORIES,
        payload: {isEsport}
    }
};

const getCategoriesForMarketGroupTypeId = (marketGroupTypeId) => {

    return async(dispatch, getState, {CategoriesApi}) => {

        dispatch(loadCategoriesForMarketGroupTypeIdPending());
        try {
            const {code, data} = await CategoriesApi.getCategoriesForMarketGroupTypeId(marketGroupTypeId);

            if(code != 200){
                throw {message:`data error`};
            }
            dispatch(loadCategoriesForMarketGroupTypeIdSuccess(marketGroupTypeId, data));

            return data;
        } catch(error){
            dispatch(loadCategoriesForMarketGroupTypeIdFailure(data));
            throw error;
        }
    }

};

const loadCategoriesForMarketGroupTypeIdPending = () => {
    return {
        type: `${LOAD_CATEGORIES_FOR_MARKET_GROUP_TYPE_ID}_PENDING`
    }
};

const loadCategoriesForMarketGroupTypeIdSuccess = (groupTypeId, categories) => {
    return {
        type: `${LOAD_CATEGORIES_FOR_MARKET_GROUP_TYPE_ID}_SUCCESS`,
        payload: {groupTypeId, categories}
    }
};

const loadCategoriesForMarketGroupTypeIdFailure = () => {
    return {
        type: `${LOAD_CATEGORIES_FOR_MARKET_GROUP_TYPE_ID}_FAILURE`
    }
};

export {
    unselectLeagueForSport,
    removeSportById,
    fetchCategories,
    selectLeagueById,
    deselectLeagueById,
    changeSportId,
    changeTimeFilter,
    toggleSelectedLeague,
    toggleSelectedLeaguesForSports,
    filterLeftMenuEsportCategories,
    fetchPopularCategories,
    selectThirdLevelCategory,
    resetSlipList,
    getCategoriesForMarketGroupTypeId,
};
