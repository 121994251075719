import React, { useState, useEffect, useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { SubmissionError, change, destroy } from 'redux-form';
import ReactTooltip from 'react-tooltip';
import store from 'store';
import _pick from 'lodash/pick';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _isEmpty from 'lodash/isEmpty';

import { translation, pushCustomEventToGoogleAnalytics } from 'utilsHelper.js';
import { registerUser, preregisterUser, verificationCode } from 'authActions.js';
import { getPromoCookie, setPromoCookie } from 'cookiesHelper.js';
import { loadPublicRegConsents, getMyIdStatus } from 'customerActions.js';
import usePrevious from 'UsePrevious.js';
import useToggle from 'UseToggle.js';
import CmsPage from 'CmsPage.js';
import ModalOpacity from 'ModalOpacity.js';
import RegisterWizardFormStep1 from 'RegisterWizardFormStep1.js';
import RegisterWizardFormStep2 from 'RegisterWizardFormStep2.js';
import RegisterStepLiner from 'RegisterStepLiner.js';
import S from 'StyledRegisterWizardForm.js';

import VerifiedIcon from 'verified-icon.svg';
import TogglerIcon from 'register-tooltip-icon.svg';
import ContactFormIcon from 'contact-form.svg';
import BankIcon from 'bank.svg';

export const TooltipToggler = ({ name, className }) => {
    return (
        <>
            <S.TooltipToggler data-tip data-for={`${name}`} className={className}>
                <S.TooltipTogglerIcon dangerouslySetInnerHTML={{ __html: TogglerIcon }}></S.TooltipTogglerIcon>
            </S.TooltipToggler>
            <ReactTooltip id={`${name}`} type="light">
                <span>{translation(`account_registerForm_${name}Tooltip`)}</span>
            </ReactTooltip> 
        </>
    );
};

const RegisterWizardForm = (props) => {
    const {
        preregisterUser,
        destroyWizardFormOnModalClose,
        registerUser,
        loadPublicRegConsents,
        getMyIdStatus,
        verificationCode
    } = props;
    const CUSTOMER_STEP_TWO_TAG = 'CUSTOMER_REGISTRATION_STEP_2';

    const [step, setNextStep] = useState(0);
    const [errorCode, setRegisterErrorCode] = useState(0);
    const [consents, setRegConsents] = useState([]);
    const [promoCodeCookie,changePromoCodeCookie] = useState(getPromoCookie())

    const prevStep = usePrevious(step);
    const [showErrorModal, toggleErrorModal] = useToggle(false);
    const [showConfirmationModal, toggleConfirmationModal] = useToggle(false);
    const { isMyIdActive } = useSelector(state => state?.Customer);

    useEffect(() => {
        getMyIdStatus()
    }, []);

    const registerOnSubmit = async (postData) => {
        try {
            const newUser = await registerUser(postData);
            app.modal.Login.loginAfterRegister(postData);
            return newUser;
        } catch (error) {
            throw error;
        }
    };

    const preregisterUserMethod = async (postData) => {
        try {
            await preregisterUser(postData);
        } catch (error) {
            throw error;
        }
    };

    const getPublicRegConsents = async () => {
        try {
            const data = await loadPublicRegConsents(CUSTOMER_STEP_TWO_TAG);
            return data.CUSTOMER_REGISTRATION_STEP_2 || [];
        } catch (error) {
            throw error;
        }
    };

    const errorsDataLayerLettersMap = useMemo(() => {
        return {
            'email': 'e',
            'password': 'h',
            'mobile': 't',
            'rodoClause': 'z',
            'firstName': 'i',
            'lastName': 'n',
            'personalIdentifier': 'p',
            'passportNumber': 'sz',
            'dateOfBirth': 'u'
        }
    }, []);

    const checkErrorsForDataLayer = (synchronousErrors, formValues) => {
        let stepErrors = synchronousErrors;

        if (step === 2) {
            if (formValues['nationality'] === 'PL') {
                delete stepErrors['dateOfBirth'];
                delete stepErrors['passportNumber'];
            } else {
                delete stepErrors['personalIdentifier'];
            }
        }

        if (Object.keys(stepErrors).length) {
            const errorsLettersArray = [];
            for (let error in stepErrors) {
                if(errorsDataLayerLettersMap[error]) {
                    errorsLettersArray.push(errorsDataLayerLettersMap[error])
                }
            }

            if (errorsLettersArray.length) {
                pushCustomEventToGoogleAnalytics({ 'event': `register-${step}step-failed`, 'errors': errorsLettersArray });
            }    
        }
    };


    // TEMP - COPIED FROM registerForm.component.js - TOTALBET forgot tell us about old features compatibility - need quick fix - should be REWRITE for REACT
    useEffect(() => {
        if (app.config.affiliates) {
            // spike abstract registerForm
            app.component.RegisterForm.loadAffiliateCode();
            app.component.RegisterForm.addAffiliateCookie();
        }

        if (app.config.enableAffiliateCampaigns) {
            app.service.Affiliates.notifyCampaigns("registration-start", {});

            const queryParams = app.router.getURLParams();
            let campaign = '';
            if(queryParams.promocode) {
                campaign = queryParams.promocode;
            } else if(queryParams.campaign) {
                campaign = queryParams.campaign;
            } else if(queryParams.utm_campaign) {
                campaign = queryParams.utm_campaign;
            } else {
                campaign = translation('registration_autocompletePromoCode');
            }
            campaign && setPromoCookie(campaign);
            changePromoCodeCookie(campaign)
        }
    }, []);

    useEffect(() => {
        const { dispatch } = store;
        if (step === 1 && step !== prevStep) {
            setTimeout(() => dispatch(change('registerWizardForm', 'password', '')), 500);
        }
    }, [step]);

    useEffect(() => {  
        getPublicRegConsents().then(data => setRegConsents(data));  
    }, [])

    const closeRegisterModal = () => {
        destroyWizardFormOnModalClose();
        app.modal.Register.hide();
    };

    const goToNextPage = () => {
        setNextStep((prevStep) => prevStep + 1);

        pushCustomEventToGoogleAnalytics({ 'event':'register-2step'})
    };

    const goToPrevPage = () => {
        setNextStep((prevStep) => prevStep - 1);
    };

    const registerFirstStep = async (values) => {
        try {
            const pickedValues = ['email', 'mobile', 'emailConsent', 'smsConsent'];
            const  registerData = _pick(values, pickedValues);

            if (_get(values, ['marketingConsent']) == true) {
                await preregisterUserMethod(registerData)
            }
            await verificationCode( _pick(values, ['mobile']));

            goToNextPage();

        } catch (error) {
            openErrorModal(error);
            throw new SubmissionError({_error: error});
        }
    };

    const openErrorModal = (error) => {
        const errorCodeMessage = error.split('_')
        let errorCode = errorCodeMessage[errorCodeMessage.length - 1];
        setRegisterErrorCode(errorCode);
        toggleErrorModal();
    };

    const registerSecondStep = async (values) => {
        try {
            let pickedValues = [
                'firstName',
                'lastName',
                'nationality',
                'password',
                'email',
                'mobile',
                'verificationCode'
            ];

            const nationality = _get(values, ['nationality']);
            if (nationality == 'PL') {
                pickedValues = [...pickedValues, 'personalIdentifier'];
            } else {
                pickedValues = [...pickedValues, 'postalCode', 'city', 'street', 'passportNumber', 'dateOfBirth'];
            }

            const promoCode = _get(values, ['promoCode']);
            if (promoCode){
                pickedValues = [...pickedValues, 'promoCode'];
            }

            let registerData = _pick(values, pickedValues);
            if ('personalIdentifier' in registerData) {
                registerData['personalIdentificationNumber'] = registerData['personalIdentifier'];
                delete registerData['personalIdentifier'];
            }

            if('street' in registerData) {
                registerData['address'] = registerData['street'];
                delete registerData['street'];
            }

            if('dateOfBirth' in registerData) {
                registerData['dateOfBirth'] = registerData['dateOfBirth'].replace(/[\/]/g,'-');
            }

            // temporary disable KASOMANIA
            registerData['accountConsents'] = [
                // {"name": "LOTTERY_PARTICIPATION", "consent": values['kasomania']},
                { "container": "LOTTERY_PARTICIPATION", "accepted": false},
                { "container": "REGULATIONS", "accepted" : true },
                { "container": "EMAIL", "accepted": `${_get(values, ['emailConsent'])}` },
                { "container": "SMS", "accepted": `${_get(values, ['smsConsent'])}` }
            ]
            
            const filteredConsents = consents.filter((container) => container.visibleForPlayer);
            filteredConsents && filteredConsents.map((bonus, index) => {
                if(_get(values, ['isBonusConsent'+index])) {
                    registerData['accountConsents'].push({ "container": "OFFERS", "accepted": `${_get(values, ['isBonusConsent'+index])}`, "definitionId": bonus.definitionId})
                } else {
                    registerData['accountConsents'].push({ "container": "OFFERS", "accepted": "false", "definitionId": bonus.definitionId});
                }
            })
            
            const customerId = await registerOnSubmit(registerData);
            if (customerId && app.config.enableAffiliateCampaigns) {
                app.service.Affiliates.notifyCampaigns("registration-end-success", {"{customerId}" : customerId});
            }
            
            pushCustomEventToGoogleAnalytics({ 'event':'register-success'})
            if(_tfa) _tfa.push({notify: 'event', name: 'complete_registration', id: 1711860}) //customer tracking Taboola event

            toggleConfirmationModal();

        } catch (error) {
            openErrorModal(error);
            throw new SubmissionError({_error: error});
        }
    };

    const secondStepInitialValues = useMemo(() => {
        const { dispatch } = store;
        let data = {
            nationality: process.env.MAIN_COUNTRY_CODE
        };

        let promo = getPromoCookie();
        if (!promo){
            promo = translation('registration_autocompletePromoCode');
        }
        data['promoCode'] = promo;

        dispatch(change('registerWizardForm', 'promoCode', promo))

        return data;
    }, [promoCodeCookie]);

    const selectMyIdRegisterMethod = () => {
        window.location.href = '/rest/customer/identity-resolver/my-id/initialize';
    };

    return (
        <>
            {showConfirmationModal ? (
                <ModalOpacity 
                    isOpen={showConfirmationModal}
                    toggleOpen={toggleConfirmationModal}
                    title="Confirmation"
                    showHeader={false}
                    preventCloseOnOverlay
                    padding={0}
                >
                    <S.ConfirmationModal>
                        <S.ConfirmationTitle dangerouslySetInnerHTML={{ __html: translation('account_registerForm_confirmationTitle') }} />
                        <S.ConfirmationIcon dangerouslySetInnerHTML={{ __html: VerifiedIcon }} />
                        <S.ConfirmationMsg dangerouslySetInnerHTML={{ __html: translation('account_registerForm_confirmationMessage') }} />
                        <S.ConfirmationCloseBtn
                            onClick={() => {
                                closeRegisterModal();
                                setTimeout(() => {
                                    toggleConfirmationModal();
                                }, 120);
                            }}
                        >
                            {translation('common_close')}
                        </S.ConfirmationCloseBtn>
                    </S.ConfirmationModal>
                </ModalOpacity>
                ) : (
                    <>
                        <ModalOpacity 
                            isOpen={showErrorModal}
                            toggleOpen={toggleErrorModal}
                            title="Error"
                            showHeader={false}
                            preventCloseOnOverlay
                            padding={0}
                        >
                            <S.ErrorModal>
                                <S.ErrorCloseBtn onClick={toggleErrorModal}>
                                    <S.CloseIcon />
                                </S.ErrorCloseBtn>
                                <S.ErrorTitle>{translation('account_registerForm_errorTitle')}</S.ErrorTitle>
                                <S.ErrorMessage>{translation(`account_registerForm_errorMessage_${errorCode}`)}</S.ErrorMessage>
                                <S.ErrorConfirmBtn 
                                    onClick={() => {
                                        toggleErrorModal();
                                        closeRegisterModal();
                                        setTimeout(() => {
                                            app.router.redirect('/');
                                        }, 500);
                                    }}
                                >
                                    {translation('account_registerForm_errorMainPage')}
                                </S.ErrorConfirmBtn>
                            </S.ErrorModal>
                        </ModalOpacity>

                        <S.RegisterWizardForm>
                            <CmsPage name={`registerFormBanner`} isPlaces /> 
                            <S.Header>
                                <S.Title>
                                    {step === 0 ? translation('account_registerForm_chooseMethod') : translation('registerForm_title')}
                                </S.Title>
                                <S.CloseIcon onClick={closeRegisterModal} />
                            </S.Header>

                            {(step === 1 || step === 2) && <RegisterStepLiner step={step} />}

                            {step === 0 && (
                            <S.RegisterOptionsContainer> 
                                <S.OptionContainer>
                                    <S.OptionLabelRow>
                                        {<S.FormOptionIcon className="" dangerouslySetInnerHTML={{__html: BankIcon}} />}
                                        {translation('account_registerForm_myBank')}
                                    </S.OptionLabelRow>
                                    <S.OptionDescription dangerouslySetInnerHTML={{__html: translation('account_registerForm_myBankDescription')}}/>
                                    <S.OptionChooseBtn onClick={selectMyIdRegisterMethod} disabled={!isMyIdActive}>
                                        {isMyIdActive? translation('account_registerForm_registerBtn') : translation('account_registerForm_registerBtn_disabled')}
                                    </S.OptionChooseBtn>
                                </S.OptionContainer>
                                <S.OptionContainer>
                                    <S.OptionLabelRow>
                                        {<S.FormOptionIcon className="" dangerouslySetInnerHTML={{__html: ContactFormIcon}} />}
                                        {translation('account_registerForm_contactForm')}
                                    </S.OptionLabelRow>
                                    <S.OptionDescription dangerouslySetInnerHTML={{__html: translation('account_registerForm_contactFormDescription')}}/>
                                    <S.OptionChooseBtn onClick={goToNextPage}>
                                        {translation('account_registerForm_registerBtn')}
                                    </S.OptionChooseBtn>
                                </S.OptionContainer>
                            </S.RegisterOptionsContainer>
                            )}

                            {step === 1 && (
                                <RegisterWizardFormStep1 
                                    onSubmit={registerFirstStep}
                                    initialValues={secondStepInitialValues}
                                    checkErrorsForDataLayer={checkErrorsForDataLayer}
                                />
                            )}
                            {step === 2 && (
                                <RegisterWizardFormStep2 
                                    goToPrevPage={goToPrevPage}
                                    initialValues={secondStepInitialValues}
                                    onSubmit={registerSecondStep}
                                    checkErrorsForDataLayer={checkErrorsForDataLayer}
                                    containers={consents}
                                />
                            )}
                        </S.RegisterWizardForm>
                    </>
                )
            } 
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    const bindedActions = {
        registerUser,
        preregisterUser,
        loadPublicRegConsents,
        getMyIdStatus,
        verificationCode
    }

    return {
        ...bindActionCreators(bindedActions, dispatch),
        destroyWizardFormOnModalClose: () => dispatch(destroy('registerWizardForm'))
    }
}

export default connect(null, mapDispatchToProps)(RegisterWizardForm);